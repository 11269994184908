import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { firstValueFrom, forkJoin, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  DELETE_SHIFT_TURNOVER,
  VIEW_AUDIT,
} from 'src/app/constants/action-constants';
import {
  MC_ST_DETAIL_CONTAINER,
  MC_ST_PRINT_CONTAINER,
} from 'src/app/constants/common.constants';
import {
  DEPARTING_COMPLETED,
  INCOMING_COMPLETED,
  PRINT_SHIFT_TURNOVER,
} from 'src/app/constants/crm-constants';
import {
  MC_ST_TAB_PREFIX,
  NEW_MC_SHIFT_TURNOVER,
} from 'src/app/constants/monlog-constants';
import { DetailsContainer } from 'src/app/core/containers/details-container';
import { DeviceSize } from 'src/app/core/enums/deviceSize.enum';
import {
  buildAuditHistory,
  clearFormArray,
  dynamicSort,
  dynamicSubfieldSort,
  getDirtyValues,
} from 'src/app/core/functions/common-functions';
import { TabService } from 'src/app/core/services/tab.service';
import { DirtyStatus } from 'src/app/model/common/dirty-status';
import { Contact } from 'src/app/model/contacts/contact';
import { ShiftTurnover } from 'src/app/model/shift/shift-turnover';
import { AuthService } from 'src/app/services/auth.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { ContactApiService } from 'src/app/services/contact-api.service';
import { ContactBuilderService } from 'src/app/services/contact-builder.service';
import { DeviceService } from 'src/app/services/device.service';
import { FacilityApiService } from 'src/app/services/facility-api.service';
import { FacilityBuilderService } from 'src/app/services/facility-builder.service';
import { FacilityTableService } from 'src/app/services/facility-table.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LocationApiService } from 'src/app/services/location-api.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { ShiftTurnoverApiService } from 'src/app/services/shift-turnover-api.service';
import { ShiftTurnoverBuilderService } from 'src/app/services/shift-turnover-builder.service';
import { isNullOrUndefined } from 'util';
import moment from 'moment-mini';
import { ShiftTurnoverTableService } from 'src/app/services/shift-turnover-table.service';
import { CLAIMS } from 'src/app/constants/auth-constants';

@Component({
    selector: 'app-mc-shift-turnover-detail',
    templateUrl: './mc-shift-turnover-detail.component.html',
    styleUrls: ['./mc-shift-turnover-detail.component.scss'],
    standalone: false
})
export class McShiftTurnoverDetailComponent
  extends DetailsContainer
  implements OnInit, OnDestroy, AfterContentChecked
{
  protected _translateService: TranslateService;
  protected _deviceService: DeviceService;
  private _fb = inject(UntypedFormBuilder);
  private _cdRef = inject(ChangeDetectorRef);
  protected _logAndMessage = inject(LogAndMessageService);
  protected _confirmationService: ConfirmationService;
  protected _locationApi: LocationApiService;
  protected _contactApi: ContactApiService;
  private _contactBuilder = inject(ContactBuilderService);
  protected _facilityApi: FacilityApiService;
  protected _facilityTableService: FacilityTableService;
  protected _facilityBuilder = inject(FacilityBuilderService);
  private _shiftTurnoverApi = inject(ShiftTurnoverApiService);
  private _shiftTurnoverTable = inject(ShiftTurnoverTableService);
  private _shiftTurnoverBuilder = inject(ShiftTurnoverBuilderService);
  private _auth = inject(AuthService);
  private _authApi = inject(AuthApiService);
  private _loader = inject(LoadingService);

  shiftTurnover: ShiftTurnover;
  isEditting = false;
  filteredContacts: any[];
  addEntryForm: UntypedFormGroup;
  showEntryDialog = false;
  saving = false;
  actions: SelectItem[] = [];
  startDate: Date;
  endDate: Date;

  addIcon = 'fa fa-fw fa-plus';
  updateIcon = 'fa fa-fw fa-save';
  isUnavailable = false;
  deleteMsg: string;
  okBtnLabel: string;
  cancelBtnLabel: string;
  deleteHeader: string;
  groups: any[];
  shiftTypes: SelectItem[];
  textAreaSize = 62;
  deviceSub: Subscription;
  selectedAction: any = null;
  canEditResolved = false;
  canEditUnresolved = false;
  canCreate = false;
  canViewDeparting = false;
  canViewIncoming = false;
  previousTurnoverStatus = '';

  doCheckComplete = false;
  allContacts: any[];
  contactsLoading: boolean;
  validationMessages: any;
  validationParams: any;

  constructor() {
    const _translateService = inject(TranslateService);
    const _deviceService = inject(DeviceService);
    const _confirmationService = inject(ConfirmationService);
    const _locationApi = inject(LocationApiService);
    const _contactApi = inject(ContactApiService);
    const _facilityApi = inject(FacilityApiService);
    const _facilityTableService = inject(FacilityTableService);

    super(
      _translateService,
      _deviceService,
      _confirmationService,
      _locationApi,
      _contactApi,
      _facilityTableService,
      _facilityApi
    );
    this._translateService = _translateService;
    this._deviceService = _deviceService;
    this._confirmationService = _confirmationService;
    this._locationApi = _locationApi;
    this._contactApi = _contactApi;
    this._facilityApi = _facilityApi;
    this._facilityTableService = _facilityTableService;

  }

  ngOnInit() {
    this.canViewDeparting = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.MONITORING_CENTER.MC_SHIFT_TURNOVERS.VIEW_ALL_MC_TURNOVER_DEPARTING_SECTIONS,
    ]);
    this.canViewIncoming = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.MONITORING_CENTER.MC_SHIFT_TURNOVERS.VIEW_ALL_MC_TURNOVER_INCOMING_SECTIONS,
    ]);

    const validations = this._shiftTurnoverBuilder.buildValidationMessages();
    this.validationMessages = validations.messages;
    this.validationParams = validations.params;

    this.setupTabClosingSubscription();
    if (this._loader.isLoaded()) {
      this.initShiftTurnover();
    } else {
      this._loader.loadingFinishedEvent.subscribe((event) => {
        this.initShiftTurnover();
      });
    }
    this.contactsLoading = true;
    this._contactApi
      .findContacts()
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = [...data.getContacts];
        this.allContacts = clone.map((c) =>
          this._contactBuilder.buildContact(c)
        );
        this.contactsLoading = false;
      });
  }

  ngOnDestroy(): void {
    if (this.deviceSub) {
      this.deviceSub.unsubscribe();
    }

    if (this.tabClosingSub) {
      this.tabClosingSub.unsubscribe();
    }

    if (this.valueSub) {
      this.valueSub.unsubscribe();
    }
  }

  ngAfterContentChecked(): void {
    const activeId = this.id;
    if (
      !isNullOrUndefined(activeId) &&
      !isNullOrUndefined(this.shiftTurnover) &&
      this.shiftTurnover.id !== activeId &&
      !this.doCheckComplete
    ) {
      this.doCheckComplete = true;
      this._shiftTurnoverTable.setSelected(undefined);
      this.initShiftTurnover();
    }
  }

  checkDeviceSize() {
    if (this.ifDeviceMatches([DeviceSize.LG, DeviceSize.MD])) {
      this.textAreaSize = 42;
    } else if (this.ifDeviceMatches([DeviceSize.XL])) {
      this.textAreaSize = 62;
    }
  }

  initShiftTurnover() {
    this.deviceSub = this._deviceService.mediaChangeEvent.subscribe((event) => {
      this.checkDeviceSize();
    });
    this.checkDeviceSize();

    const id = this.id;
    if (id) {
      this._shiftTurnoverApi
        .getShiftTurnover(id)
        .pipe(take(1))
        .subscribe(({ data }) => {
          const clone = Object.assign({}, data);
          this.shiftTurnover = this._shiftTurnoverBuilder.buildShiftTurnover(
            clone.getShiftTurnover
          );
          this.doCheckComplete = false;
          this.loadShiftData();
        });
    } else {
      this.shiftTurnover = this._shiftTurnoverTable.getSelected();
      if (this.shiftTurnover) {
        this.isEditting = true;
      } else {
        this._logAndMessage.errorLogOnly('MC shift turnover was null.');
        const index = TabService.getInstance().getActiveIndex();
        TabService.getInstance().closeTab(index);
      }
      this.loadShiftData();
    }
  }

  loadShiftData() {
    if (!this.canViewDeparting || !this.canViewIncoming) {
      this._contactApi
        .getContactByEmail(this._auth.getEmail())
        .pipe(take(1))
        .subscribe((data: Contact) => {
          const id = data.id;
          this.shiftTurnover.ShiftDetails.forEach((s) => {
            if (s.incomingDepartingFlag === 'D' && s.analystId === id) {
              this.canViewDeparting = true;
            }

            if (s.incomingDepartingFlag === 'I' && s.analystId === id) {
              this.canViewIncoming = true;
            }
          });
        });
    }

    this._shiftTurnoverApi
      .loadAvailableGroups()
      .pipe(take(1))
      .subscribe(({ data }) => {
        this.groups = data.getShiftGroups;
      });

    this._shiftTurnoverApi
      .loadAvailableTypes()
      .pipe(take(1))
      .subscribe(({ data }) => {
        this.shiftTypes = [
          { label: 'Choose', value: null },
          ...data.getShiftTypes
            .filter((d) => d.ShiftGroup.name === 'Monitoring Center')
            .map((d) => ({ label: d.name, value: d.id })),
        ];
        this.initialize();
      });
  }

  initialize() {
    this.canEditResolved = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.MONITORING_CENTER.MC_SHIFT_TURNOVERS.EDIT_MC_RESOLVED_TURNOVERS,
    ]);
    this.canEditUnresolved = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.MONITORING_CENTER.MC_SHIFT_TURNOVERS.EDIT_MC_UNRESOLVED_TURNOVERS,
    ]);
    this.canCreate = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.MONITORING_CENTER.MC_SHIFT_TURNOVERS.CREATE_MC_TURNOVERS,
    ]);

    if (isNullOrUndefined(this.shiftTurnover.id)) {
      TabService.getInstance().updateActiveTabLabel(NEW_MC_SHIFT_TURNOVER);
      this.loading = false;
    } else {
      const header =
        MC_ST_TAB_PREFIX +
        this.shiftTurnover.shift +
        ' - ' +
        moment(this.shiftTurnover.shiftDate).format('MM/DD/YYYY').toString();
      TabService.getInstance().updateActiveTabLabel(header);
      this.loading = false;
    }

    this.buildForm();
    this.updateForm();
    this.updateActionsList();
  }

  buildForm() {
    this.form = this._fb.group({
      incomingAnalyst: [null, Validators.required],
      departingAnalyst: [null, Validators.required],
      shiftType: [null, Validators.required],
      shiftDate: [null, Validators.required],
      createdDt: [{ value: null, disabled: true }],
      status: [{ value: null, disabled: true }],
      incomingAdditionalComments: [
        null,
        this._shiftTurnoverBuilder.commentsTooLongValidator(),
      ],
      departingAdditionalComments: [
        null,
        this._shiftTurnoverBuilder.commentsTooLongValidator(),
      ],
      departingFitFlag: [null],
      incomingFitFlag: [null],
      items: this._fb.array([]),
      mitigations: this._fb.array([]),
      businessUnit: [null],
    });

    this.valueSub = this.form.valueChanges.subscribe((changes) => {
      if (this.form.dirty) {
        this.changesEvent.emit({
          isDirty: true,
          index: this.index(),
          id: this.id,
        });
      }
    });
  }

  updateForm() {
    const inArray = this.shiftTurnover.ShiftDetails.filter(
      (d) => d.incomingDepartingFlag === 'I'
    );
    const depArray = this.shiftTurnover.ShiftDetails.filter(
      (d) => d.incomingDepartingFlag === 'D'
    );
    let statusValue = 'New';
    if (this.shiftTurnover.status && this.shiftTurnover.status === 'DC') {
      statusValue = 'Departing Complete';
    } else if (this.shiftTurnover.status && this.shiftTurnover.status === 'R') {
      statusValue = 'Resolved';
    }

    this.form.patchValue({
      incomingAnalyst:
        inArray.length > 0
          ? {
              id: inArray[0].Analyst.id,
              fullName:
                inArray[0].Analyst.firstName +
                ' ' +
                inArray[0].Analyst.lastName,
            }
          : null,
      departingAnalyst:
        depArray.length > 0
          ? {
              id: depArray[0].Analyst.id,
              fullName:
                depArray[0].Analyst.firstName +
                ' ' +
                depArray[0].Analyst.lastName,
            }
          : null,
      shiftType: this.shiftTurnover.ShiftType
        ? this.shiftTurnover.ShiftType.id
        : null,
      shiftDate: this.shiftTurnover.shiftDate,
      status: statusValue,
      createdDt: this.shiftTurnover.createdAt,
      businessUnit: null,
    });

    let incoming,
      departing = null;
    if (
      this.shiftTurnover.ShiftDetails &&
      this.shiftTurnover.ShiftDetails.length > 0
    ) {
      incoming = this.shiftTurnover.ShiftDetails.filter(
        (d) => d.incomingDepartingFlag === 'I'
      )[0];
      departing = this.shiftTurnover.ShiftDetails.filter(
        (d) => d.incomingDepartingFlag === 'D'
      )[0];
      this.form.patchValue({
        incomingAdditionalComments: incoming.additionalComments,
        departingAdditionalComments: departing.additionalComments,
        departingFitFlag: departing.fitFlag,
        incomingFitFlag: incoming.fitFlag,
      });

      const array = incoming.ShiftTurnoverItems.filter(
        (co) => co.dirtyStatus !== DirtyStatus.DELETED
      ).sort(dynamicSubfieldSort('ShiftItem', 'sortOrder', 1));
      const depItemArray = departing.ShiftTurnoverItems.filter(
        (co) => co.dirtyStatus !== DirtyStatus.DELETED
      ).sort(dynamicSubfieldSort('ShiftItem', 'sortOrder', 1));

      const itemsArray = this.form.get('items') as UntypedFormArray;
      clearFormArray(itemsArray);
      for (let i = 0; i < array.length; i++) {
        const c = array[i];
        const d = depItemArray[i];
        itemsArray.push(
          this._fb.group({
            incomingId: c.id,
            departingId: d.id,
            incomingName: c.ShiftItem.name,
            incomingShiftItemId: c.ShiftItem.id,
            departingShiftItemId: d.ShiftItem.id,
            incomingComments: [
              c.comments,
              this._shiftTurnoverBuilder.commentsTooLongValidator(),
            ],
            departingComments: [
              d.comments,
              this._shiftTurnoverBuilder.commentsTooLongValidator(),
            ],
            incomingTurnoverFlag: c.turnoverFlag,
            departingTurnoverFlag: d.turnoverFlag,
          })
        );
      }

      const depMitArray = departing.ShiftTurnoverMitigations.filter(
        (co) => co.dirtyStatus !== DirtyStatus.DELETED
      ).sort(dynamicSubfieldSort('ShiftMitigation', 'sortOrder', 1));
      const mitArray = this.form.get('mitigations') as UntypedFormArray;
      clearFormArray(mitArray);
      for (let j = 0; j < depMitArray.length; j++) {
        const mit = depMitArray[j];
        mitArray.push(
          this._fb.group({
            id: mit.id,
            mitigationId: mit.ShiftMitigation.id,
            mitigationName: mit.ShiftMitigation.name,
            turnoverFlag: mit.turnoverFlag,
          })
        );
      }
    }

    this.form.markAsPristine();

    if (!this.isEditting) {
      this.form.disable();
    } else {
      this.setEditting();
    }
  }

  checkAnalystCompleted() {
    if (
      this.shiftTurnover &&
      !this._authApi.doesUserHaveAllClaimsFromList([CLAIMS.MONITORING_CENTER.MC_SHIFT_TURNOVERS.EDIT_LOCKED_MC_TURNOVERS])
    ) {
      if (this.shiftTurnover.incomingCompletedDate != null) {
        const groups = (this.form.controls.items as FormArray).controls;
        groups.forEach((group: FormGroup) => {
          let incomingComments = group.get('incomingComments');
          let incomingTurnoverFlags = group.get('incomingTurnoverFlag');
          incomingComments.disable();
          incomingTurnoverFlags.disable();
        });
        // The Additional Comments field in the Departing/Incoming sections were flipped previously.
        // Since we don't know how long these have been like this, switching the fields back would
        // cause the old mon logs to display information incorrectly.
        this.form.get('departingAdditionalComments').disable();
        this.form.get('incomingFitFlag').disable();
      }

      if (this.shiftTurnover.departingCompletedDate != null) {
        const groups = (this.form.controls.items as FormArray).controls;
        groups.forEach((group: FormGroup) => {
          let departingComments = group.get('departingComments');
          let departingTurnoverFlags = group.get('departingTurnoverFlag');
          departingComments.disable();
          departingTurnoverFlags.disable();
        });
        this.form.get('mitigations').disable();
        this.form.get('incomingAdditionalComments').disable();
        this.form.get('departingFitFlag').disable();
      }
    }
  }

  async setEditting() {
    if (this._authApi.doesUserHaveClaim(CLAIMS.MONITORING_CENTER.MC_SHIFT_TURNOVERS.EDIT_ALL_MC_TURNOVER_SECTIONS)) {
      this.form.enable();
    } else if (this._authApi.doesUserHaveClaim(CLAIMS.MONITORING_CENTER.MC_SHIFT_TURNOVERS.EDIT_OWN_MC_TURNOVER_SECTIONS)) {
      this.form.enable();
      this.loading = true;
      // determine if the user is either the departing or incoming & only enable those sections
      const contact = await firstValueFrom(
        this._contactApi.getContactByEmail(this._auth.getEmail())
      );
      if (!this.shiftTurnover) {
        this._logAndMessage.translateToErrorMessage({
          headerKey: 'CRM.MESSAGES.HEADERS.UNEXPECTED_ERROR',
          bodyKey: 'CRM.MESSAGES.ERROR.UNEXPECTED_ERROR',
        });
        console.error(
          'Somehow user made it to set editting with no shift turnover!'
        );
        return;
      }

      let userIsOnThisTurnover = false;

      const departingDetail = this.shiftTurnover.ShiftDetails?.filter(
        (sd) => sd.incomingDepartingFlag === 'D'
      )[0];
      if (departingDetail?.analystId !== contact.id) {
        console.log('user is not departing here, disabling fields');
        userIsOnThisTurnover = true;
        const groups = (this.form.controls.items as FormArray).controls;
        groups.forEach((group: FormGroup) => {
          let departingComments = group.get('departingComments');
          let departingTurnoverFlags = group.get('departingTurnoverFlag');
          departingComments.disable();
          departingTurnoverFlags.disable();
        });
        this.form.get('mitigations').disable();
        this.form.get('incomingAdditionalComments').disable();
        this.form.get('departingFitFlag').disable();
      }

      const incoming = this.shiftTurnover.ShiftDetails?.filter(
        (sd) => sd.incomingDepartingFlag === 'I'
      )[0];
      if (incoming?.analystId !== contact.id) {
        console.log('user is not incoming here, disabling fields');
        userIsOnThisTurnover = true;
        const groups = (this.form.controls.items as FormArray).controls;
        groups.forEach((group: FormGroup) => {
          let incomingComments = group.get('incomingComments');
          let incomingTurnoverFlags = group.get('incomingTurnoverFlag');
          incomingComments.disable();
          incomingTurnoverFlags.disable();
        });
        this.form.get('departingAdditionalComments').disable();
        this.form.get('incomingFitFlag').disable();
      }

      this.loading = false;

      // If we haven't actually created the turnover yet we want to make sure not to disable
      // the form otherwise we will prevent all creations
      const isInitialCreate = this.shiftTurnover.ShiftDetails.length === 0;

      if (!userIsOnThisTurnover && !isInitialCreate) {
        this.isEditting = false;
        this.form.disable();
        this._logAndMessage.translateToErrorMessage({
          headerKey: 'CRM.MESSAGES.HEADERS.NO_TURNOVER_EDIT_PERMISSIONS',
          bodyKey: 'CRM.MESSAGES.ERROR.NO_TURNOVER_EDIT_PERMISSIONS',
        });
        return;
      }
    } else {
      this._logAndMessage.translateToErrorMessage({
        headerKey: 'CRM.MESSAGES.HEADERS.NO_TURNOVER_EDIT_PERMISSIONS',
        bodyKey: 'CRM.MESSAGES.ERROR.NO_TURNOVER_EDIT_PERMISSIONS',
      });
      return;
    }

    this.isEditting = true;
    this.checkAnalystCompleted();
    this.form.controls['status'].disable();
    this.form.controls['createdDt'].disable();
  }

  updateActionsList() {
    const selectAction$ = this._translateService.get('COMMON.LABEL.CHOOSE');
    const departingComplete$ = this._translateService.get(
      'CRM.LABEL.DEPARTING_COMPLETE'
    );
    const incomingComplete$ = this._translateService.get(
      'CRM.LABEL.INCOMING_COMPLETE'
    );
    const resolve$ = this._translateService.get('CRM.LABEL.RESOLVE');
    const delete$ = this._translateService.get('CRM.LABEL.DELETE');
    const print$ = this._translateService.get('COMMON.LABEL.PRINT');
    const viewAudit$ = this._translateService.get(
      'COMMON.LABEL.VIEW_AUDIT_INFO'
    );

    let select,
      dc,
      ic,
      resolve,
      del,
      print,
      audit = null;
    forkJoin([
      selectAction$,
      departingComplete$,
      incomingComplete$,
      resolve$,
      delete$,
      print$,
      viewAudit$,
    ])
      .pipe(take(1))
      .subscribe((messages) => {
        select = messages[0];
        dc = messages[1];
        ic = messages[2];
        resolve = messages[3];
        del = messages[4];
        print = messages[5];
        audit = messages[6];

        this.actions = [
          { label: select, value: null },
          { label: print, value: 'print-shift' },
        ];

        if (this.shiftTurnover.status === 'N') {
          this.actions.push({ label: dc, value: 'departing-complete' });
        }

        if (this.shiftTurnover.status === 'DC') {
          this.actions.push({ label: ic, value: 'incoming-complete' });
        }

        if (this.canEditResolved || this.canEditUnresolved) {
          this.actions.push({ label: del, value: DELETE_SHIFT_TURNOVER });
        }

        this.actions.push({ label: audit, value: VIEW_AUDIT });

        this.actions = [...this.actions];
      });
  }

  actionEvent($event, object?: any) {
    this.selectedAction = null;
    if ($event.value) {
      if ($event.value === DEPARTING_COMPLETED) {
        this.shiftTurnover.ShiftDetails.forEach((d) => {
          if (d.incomingDepartingFlag === 'D') {
            let saveMe = true;
            const anyFalseItems = d.ShiftTurnoverItems.some(
              (i) => i.turnoverFlag === false
            );
            if (anyFalseItems === true) {
              this._logAndMessage.translateToErrorMessage({
                headerKey: 'CRM.MESSAGES.HEADERS.DEPARTING_NOT_COMPLETE',
                bodyKey: 'CRM.MESSAGES.ERROR.MISSING_ITEMS',
              });
              saveMe = false;
            }

            const mitigations = d.ShiftTurnoverMitigations
              ? d.ShiftTurnoverMitigations.filter(
                  (m) => m.turnoverFlag === true
                )
              : [];
            if (!d.fitFlag) {
              this._logAndMessage.translateToErrorMessage({
                headerKey: 'CRM.MESSAGES.HEADERS.DEPARTING_NOT_COMPLETE',
                bodyKey: 'CRM.MESSAGES.ERROR.FIT_MISSING',
              });
              saveMe = false;
            }

            if (mitigations.length < 1) {
              this._logAndMessage.translateToErrorMessage({
                headerKey: 'CRM.MESSAGES.HEADERS.DEPARTING_NOT_COMPLETE',
                bodyKey: 'CRM.MESSAGES.ERROR.CRM_MIT_MISSING',
              });
              saveMe = false;
            }
            if (saveMe === true) {
              this.previousTurnoverStatus = this.shiftTurnover.status;
              this.shiftTurnover.status = 'DC';
              this.saveShiftTurnover();
            }
          }
        });
      } else if ($event.value === INCOMING_COMPLETED) {
        this.shiftTurnover.ShiftDetails.forEach((d) => {
          if (d.incomingDepartingFlag === 'I') {
            let saveMe = true;
            const anyFalseItems = d.ShiftTurnoverItems.some(
              (i) => i.turnoverFlag === false
            );
            if (anyFalseItems === true) {
              this._logAndMessage.translateToErrorMessage({
                headerKey: 'CRM.MESSAGES.HEADERS.INCOMING_NOT_COMPLETE',
                bodyKey: 'CRM.MESSAGES.ERROR.MISSING_ITEMS',
              });
              saveMe = false;
            }

            if (!d.fitFlag) {
              this._logAndMessage.translateToErrorMessage({
                headerKey: 'CRM.MESSAGES.HEADERS.INCOMING_NOT_COMPLETE',
                bodyKey: 'CRM.MESSAGES.ERROR.FIT_MISSING',
              });
              saveMe = false;
            }

            if (saveMe === true) {
              this.previousTurnoverStatus = this.shiftTurnover.status;
              this.shiftTurnover.status = 'R';
              this.saveShiftTurnover();
            }
          }
        });
      } else if ($event.value === PRINT_SHIFT_TURNOVER) {
        const tab = TabService.getInstance().buildNewTab(
          MC_ST_PRINT_CONTAINER,
          true,
          null,
          this.shiftTurnover.id
        );
        TabService.getInstance().openTab(tab);
      } else if ($event.value === VIEW_AUDIT) {
        this.auditLoading = true;
        this._shiftTurnoverApi
          .findShiftTurnoverHistory(this.shiftTurnover.id)
          .pipe(take(1))
          .subscribe(
            ({ data }) => {
              const clone = Object.assign({}, data);
              this.history = [
                ...buildAuditHistory(clone.getShiftTurnoverHistory),
              ];
              this._cdRef.markForCheck();
            },
            (error) => {
              this.auditLoading = false;
            },
            () => {
              this.auditLoading = false;
              this.displayAuditDialog = true;
            }
          );
      } else if ($event.value === DELETE_SHIFT_TURNOVER) {
        if (
          (this.shiftTurnover.status !== 'R' && !this.canEditUnresolved) ||
          (this.shiftTurnover.status == 'R' && !this.canEditResolved)
        ) {
          return;
        }
        let confHeader = null,
          ok = null,
          cancel = null,
          message = null;
        const confHeader$ = this._translateService.get(
          'CRM.MESSAGES.HEADERS.DELETE_TURNOVER'
        );
        const ok$ = this._translateService.get('COMMON.LABEL.BUTTONS.YES');
        const cancel$ = this._translateService.get('COMMON.LABEL.BUTTONS.NO');
        const message$ = this._translateService.get(
          'CRM.MESSAGES.CONFIRMATION.DELETE_TURNOVER'
        );

        forkJoin([confHeader$, ok$, cancel$, message$]).subscribe(
          (messages) => {
            confHeader = messages[0];
            ok = messages[1];
            cancel = messages[2];
            message = messages[3];
          }
        );
        this._confirmationService.confirm({
          header: confHeader,
          icon: 'fa fa-question-circle',
          acceptVisible: true,
          rejectVisible: true,
          acceptLabel: ok,
          rejectLabel: cancel,
          message: message,
          accept: () => {
            this.saving = true;
            this._shiftTurnoverApi
              .deleteShiftTurnover(this.shiftTurnover.id)
              .pipe(take(1))
              .subscribe((result) => {
                this.saving = false;
                this._logAndMessage.translateToSuccessMessage(
                  {
                    bodyKey: 'COMMON.MESSAGES.SUCCESS.DELETE_SUCCESS_MSG',
                    headerKey: 'COMMON.MESSAGES.HEADERS.DELETE',
                  },
                  true
                );
                const index = TabService.getInstance().getActiveIndex();
                TabService.getInstance().closeTab(index);
              });
          },
          reject: () => {},
        });
      }

      if (!isNullOrUndefined(object)) {
        object.clear(null);
      }
    }
    this._cdRef.markForCheck();
  }

  contactSearch($event) {
    const query = $event && $event.query ? $event.query.toLowerCase() : '';
    this._contactApi
      .getTurnoverContacts(this.shiftTurnover.shiftGroupId)
      .pipe(take(1))
      .subscribe(({ data }) => {
        this.filteredContacts = [...data.getTurnoverContacts]
          .sort(dynamicSort('firstName', 1))
          .filter((c) =>
            (c.firstName + ' ' + c.lastName).toLowerCase().startsWith(query)
          )
          .map((c: Contact) => ({
            id: c.id,
            fullName: c.firstName + ' ' + c.lastName,
          }));
      });
  }

  saveShiftTurnover() {
    if (!this.canCreate && !this.canEditUnresolved && !this.canEditResolved) {
      return;
    }

    const dv = getDirtyValues(this.form);
    this.saving = true;
    this.isEditting = false;
    if (this.shiftTurnover.id) {
      const updateStatement = this._shiftTurnoverBuilder.buildUpdateStatement(
        this.shiftTurnover,
        dv
      );
      this.updateShiftTurnover(updateStatement);
    } else {
      const createStatement = this._shiftTurnoverBuilder.buildCreateStatement(
        this.shiftTurnover,
        dv
      );
      this.createShiftTurnover(createStatement);
    }
  }

  updateShiftTurnover(updateStatement) {
    if (
      (this.previousTurnoverStatus !== 'R' && !this.canEditUnresolved) ||
      (this.previousTurnoverStatus === 'R' && !this.canEditResolved)
    ) {
      return;
    }
    this._shiftTurnoverApi
      .updateShiftTurnover(this.shiftTurnover, updateStatement)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          this.previousTurnoverStatus = '';
          this.handleShiftTurnoverSave(
            Object.assign({}, data.updateShiftTurnover)
          );
        },
        (error) => {
          console.log(error);
          this.saving = false;
        }
      );
  }

  createShiftTurnover(updateStatement) {
    if (!this.canCreate) {
      return;
    }
    this._shiftTurnoverApi
      .createShiftTurnover(this.shiftTurnover, updateStatement)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          this.handleShiftTurnoverSave(
            Object.assign({}, data.createShiftTurnover)
          );
        },
        (error) => {
          console.log(error);
          this.saving = false;
        },
        () => {
          const oldTab = TabService.getInstance().getActiveTab();
          const newTab = TabService.getInstance().buildNewTab(
            MC_ST_DETAIL_CONTAINER,
            true,
            null,
            this.shiftTurnover.id
          );
          TabService.getInstance().replaceTab(oldTab, newTab);
        }
      );
  }

  handleShiftTurnoverSave(data) {
    this._logAndMessage.translateToSuccessMessage({
      bodyKey: 'COMMON.MESSAGES.SUCCESS.SAVED_SUCCESSFULLY',
      headerKey: 'COMMON.MESSAGES.HEADERS.SUBMIT',
    });
    this.shiftTurnover = Object.assign(
      {},
      this._shiftTurnoverBuilder.buildShiftTurnover(data)
    );
    this.saving = false;
    this.updateForm();
    this.form.disable();
    this.updateActionsList();
    this._cdRef.markForCheck();
    this.changesEvent.emit({ isDirty: false, index: this.index(), id: this.id });
  }

  shouldShowEditSaveButtons() {
    if (
      (this.shiftTurnover.status !== 'R' && this.canEditUnresolved) ||
      (this.shiftTurnover.status == 'R' && this.canEditResolved)
    ) {
      return true;
    }
    return false;
  }

  refresh(): void {
    if (
      !isNullOrUndefined(this.shiftTurnover) &&
      !isNullOrUndefined(this.shiftTurnover.id)
    ) {
      this.saving = true;
      this._shiftTurnoverApi
        .getShiftTurnover(this.shiftTurnover.id)
        .pipe(take(1))
        .subscribe(
          ({ data }) => {
            const clone = Object.assign({}, data);
            this.shiftTurnover = this._shiftTurnoverBuilder.buildShiftTurnover(
              clone.getShiftTurnover
            );
            this.loadShiftData();
          },
          (error) => {
            this.saving = false;
          },
          () => {
            this.saving = false;
          }
        );
    }
  }

  getChildControls(key: string): AbstractControl[] {
    const keyArray = this.form.get(key) as FormArray;
    return keyArray.controls;
  }

  getBaseFormChild(key: string): FormControl {
    return this.form.get(key) as FormControl;
  }

  getItemFormChild(groupIndex: number, key: string): FormControl {
    return this.form.get('items').get(`${groupIndex}`).get(key) as FormControl;
  }
}
