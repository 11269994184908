import { AfterContentChecked, Component, OnInit, OnDestroy, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { take } from 'rxjs/operators';
import { PRINT_TAB_PREFIX } from 'src/app/constants/common.constants';
import { DetailsContainer } from 'src/app/core/containers/details-container';
import { dynamicSubfieldSort } from 'src/app/core/functions/common-functions';
import { TabService } from 'src/app/core/services/tab.service';
import { DirtyStatus } from 'src/app/model/common/dirty-status';
import { ShiftTurnover } from 'src/app/model/shift/shift-turnover';
import { ContactApiService } from 'src/app/services/contact-api.service';
import { DeviceService } from 'src/app/services/device.service';
import { FacilityApiService } from 'src/app/services/facility-api.service';
import { FacilityBuilderService } from 'src/app/services/facility-builder.service';
import { FacilityTableService } from 'src/app/services/facility-table.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LocationApiService } from 'src/app/services/location-api.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { ShiftTurnoverApiService } from 'src/app/services/shift-turnover-api.service';
import { ShiftTurnoverBuilderService } from 'src/app/services/shift-turnover-builder.service';
import { ShiftTurnoverTableService } from 'src/app/services/shift-turnover-table.service';
import { isNullOrUndefined } from 'util';

@Component({
    selector: 'app-mc-shift-turnover-print-container',
    templateUrl: './mc-shift-turnover-print-container.component.html',
    styleUrls: ['./mc-shift-turnover-print-container.component.scss'],
    standalone: false
})
export class McShiftTurnoverPrintContainerComponent extends DetailsContainer implements OnInit, OnDestroy, AfterContentChecked {
  protected _translateService: TranslateService;
  protected _deviceService: DeviceService;
  protected _logAndMessage = inject(LogAndMessageService);
  protected _confirmationService: ConfirmationService;
  private _builder = inject(ShiftTurnoverBuilderService);
  private _api = inject(ShiftTurnoverApiService);
  protected _locationApi: LocationApiService;
  protected _contactApi: ContactApiService;
  protected _facilityApi: FacilityApiService;
  protected _facilityBuilder = inject(FacilityBuilderService);
  protected _shiftTable = inject(ShiftTurnoverTableService);
  protected _facilityTableService: FacilityTableService;
  private _loader = inject(LoadingService);


  shiftTurnover: ShiftTurnover;
  incomingAnalyst: string;
  departingAnalyst: string;
  statusValue: string;
  detailsObj: any;
  itemsArray: any[];
  mitArray: any[];
  doCheckComplete = false;

    constructor() {
      const _translateService = inject(TranslateService);
      const _deviceService = inject(DeviceService);
      const _confirmationService = inject(ConfirmationService);
      const _locationApi = inject(LocationApiService);
      const _contactApi = inject(ContactApiService);
      const _facilityApi = inject(FacilityApiService);
      const _facilityTableService = inject(FacilityTableService);

      super(_translateService, _deviceService, _confirmationService,
        _locationApi, _contactApi, _facilityTableService, _facilityApi);
      this._translateService = _translateService;
      this._deviceService = _deviceService;
      this._confirmationService = _confirmationService;
      this._locationApi = _locationApi;
      this._contactApi = _contactApi;
      this._facilityApi = _facilityApi;
      this._facilityTableService = _facilityTableService;

      }


  ngOnInit() {
    this.setupTabClosingSubscription();
    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.pipe(take(1)).subscribe(() => {
        this.initialize();
      });
    }
  }

  ngAfterContentChecked(): void {
    const activeId = this.id;
    if (!isNullOrUndefined(activeId) && !isNullOrUndefined(this.shiftTurnover) && this.shiftTurnover.id !== activeId
      && !this.doCheckComplete) {
      this.doCheckComplete = true;
      this._shiftTable.setSelected(undefined);
      this.initialize();
    }
  }

  ngOnDestroy(): void {
    if (this.tabClosingSub) {
      this.tabClosingSub.unsubscribe();
    }
  }

  initialize() {
    const id = this.id;

    if (!id) {
      this.shiftTurnover = this._shiftTable.getSelected();
      // no id and nothing cached, go back to search.
      if (!this.shiftTurnover) {
        this._logAndMessage.errorLogOnly('MC shift turnover was null.');
        const index = TabService.getInstance().getActiveIndex();
        TabService.getInstance().closeTab(index);
      }
      this.updateTabHeader();
      this.setupScreen();
    } else {

      this._api.getShiftTurnover(id).pipe(take(1)).subscribe(({ data }) => {
        if (data) {
          const clone = Object.assign({}, data);
          this.shiftTurnover = this._builder.buildShiftTurnover(clone.getShiftTurnover);
          this.doCheckComplete = false;
          this.updateTabHeader();
          this.setupScreen();
        } else {
          // nothing found, go back to search.
          this._logAndMessage.errorLogOnly('Could not find MC shift turnover with ID: ' + id);
          const index = TabService.getInstance().getActiveIndex();
          TabService.getInstance().closeTab(index);
        }
      });
    }
  }

  setupScreen() {
    const inArray = this.shiftTurnover.ShiftDetails.filter((d) => d.incomingDepartingFlag === 'I');
    const depArray = this.shiftTurnover.ShiftDetails.filter((d) => d.incomingDepartingFlag === 'D');
    this.statusValue = this._builder.getStatusLabelForStatus(this.shiftTurnover.status);

    this.incomingAnalyst = (inArray.length > 0) ? inArray[0].Analyst.firstName + ' ' + inArray[0].Analyst.lastName : null;
    this.departingAnalyst = (depArray.length > 0) ? depArray[0].Analyst.firstName + ' ' + depArray[0].Analyst.lastName : null;

    let incoming, departing = null;
    if (this.shiftTurnover.ShiftDetails && this.shiftTurnover.ShiftDetails.length > 0) {
      incoming = this.shiftTurnover.ShiftDetails.filter((d) => d.incomingDepartingFlag === 'I')[0];
      departing = this.shiftTurnover.ShiftDetails.filter((d) => d.incomingDepartingFlag === 'D')[0];
      this.detailsObj = {
        incomingAdditionalComments: incoming.additionalComments,
        incomingFitFlag: incoming.fitFlag,
        incomingScadaFlag: incoming.scadaFlag,
        departingAdditionalComments: departing.additionalComments,
        departingFitFlag: departing.fitFlag,
        departingScadaFlag: departing.scadaFlag,
      };

      const array = incoming.ShiftTurnoverItems.filter((co) => co.dirtyStatus !== DirtyStatus.DELETED)
        .sort(dynamicSubfieldSort('ShiftItem', 'sortOrder', 1));
      const depItemArray = departing.ShiftTurnoverItems.filter((co) => co.dirtyStatus !== DirtyStatus.DELETED)
        .sort(dynamicSubfieldSort('ShiftItem', 'sortOrder', 1));

      this.itemsArray = [];
      for (let i = 0; i < array.length; i++) {
        const c = array[i];
        const d = depItemArray[i];
        this.itemsArray.push({
          incomingId: c.id,
          departingId: d.id,
          incomingName: c.ShiftItem.name,
          incomingShiftItemId: c.ShiftItem.id,
          departingShiftItemId: d.ShiftItem.id,
          incomingComments: c.comments,
          departingComments: d.comments,
          incomingTurnoverFlag: c.turnoverFlag,
          departingTurnoverFlag: d.turnoverFlag
        });
      }

      const depMitArray = departing.ShiftTurnoverMitigations.filter((co) => co.dirtyStatus !== DirtyStatus.DELETED)
        .sort(dynamicSubfieldSort('ShiftMitigation', 'sortOrder', 1));
      this.mitArray = [];
      for (let j = 0; j < depMitArray.length; j++) {
        const mit = depMitArray[j];
        this.mitArray.push({
          id: mit.id,
          mitigationId: mit.ShiftMitigation.id,
          mitigationName: mit.ShiftMitigation.name,
          turnoverFlag: mit.turnoverFlag
        });
      }
    }
  }

  print() {
    // This used to use printjs, but we ran into some issues with lines
    // overlapping each other in the migration to angular 13, so now
    // we use @media print styling to only show what is needed
    // and use the default window.print() to generate the document!
    window.print();
  }

  updateTabHeader(): void {
    const header = PRINT_TAB_PREFIX + this.shiftTurnover.shift + ' - ' + this.shiftTurnover.shiftDate.toISOString();
    TabService.getInstance().updateActiveTabLabel(header);
  }
}
