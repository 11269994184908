<app-progress-spinner [enabled]="!monLog"></app-progress-spinner>

@if (monLog) {
  <div id="printMe" style="background-color: white; width: 800px">
    <div class="ui-g ui-fluid">
      <div class="ui-g-2">
        <button
          pButton
          label="Print"
          class="small-margin"
          (click)="print()"
        ></button>
      </div>
      <div class="ui-g-12 no-padding-top-bottom">
        @if (monLog.id) {
          <div class="ui-g-12 no-padding-top-bottom">
            <h3>{{ monLog?.logId }}</h3>
            <span class="ui-g-4 label"
              >{{ "MONLOG.LABEL.CREATED_BY" | translate }}:</span
              >
              <span class="ui-g-8 free-text-value"
                >{{ monLog.createdBy }} on
                {{ monLog.createdAt | date : "MM/dd/yyyy hh:mm:ss a" }}</span
                >
                <span class="ui-g-4 label"
                  >{{ "MONLOG.LABEL.LAST_UPDATED" | translate }}:</span
                  >
                  <span class="ui-g-8 free-text-value">{{
                    monLog.updatedAt | date : "MM/dd/yyyy hh:mm:ss a"
                  }}</span>
                </div>
              }
              @if (monLog.id) {
                <div class="ui-g-12 no-padding-top-bottom">
                  <span class="ui-g-4 label"
                    >{{ "MONLOG.LABEL.CURRENT_STATUS" | translate }}:</span
                    >
                    @if (monLog.logStatus === 'O') {
                      <span class="ui-g-8 free-text-value">{{
                        "MONLOG.LABEL.OPEN" | translate
                      }}</span>
                    }
                    @if (monLog.logStatus === 'A') {
                      <span class="ui-g-8 free-text-value">{{
                        "MONLOG.LABEL.AWAITING_REVIEW" | translate
                      }}</span>
                    }
                    @if (monLog.logStatus === 'C') {
                      <span class="ui-g-8 free-text-value">{{
                        "MONLOG.LABEL.REVIEW_COMPLETE" | translate
                      }}</span>
                    }
                    @if (monLog.logStatus === 'R') {
                      <span class="ui-g-8 free-text-value">{{
                        "MONLOG.LABEL.RESOLVED" | translate
                      }}</span>
                    }
                    @if (monLog.reviewRequestedDt) {
                      <span class="ui-g-4 label"
                        >{{ "MONLOG.LABEL.REVIEW_REQUESTED" | translate }}:</span
                        >
                      }
                      @if (monLog.reviewRequestedDt) {
                        <span class="ui-g-8 free-text-value">{{
                          monLog.reviewRequestedDt | date : "MM/dd/yyyy hh:mm:ss a"
                        }}</span>
                      }
                    </div>
                  }
                  <div class="ui-g-12 no-padding-top-bottom">
                    <span class="ui-g-4 label"
                      >{{ "MONLOG.LABEL.STATUS_SUMMARY" | translate }}:</span
                      >
                      <span class="ui-g-8">{{ monLog?.statusSummary }}</span>
                    </div>
                    @if (monLog.id) {
                      <div class="ui-g-12 no-padding-top-bottom">
                        @if (monLog.reviewCompletedDt) {
                          <span class="ui-g-4 label"
                            >{{ "MONLOG.LABEL.REVIEW_COMPLETED" | translate }}:</span
                            >
                          }
                          @if (monLog.reviewCompletedDt) {
                            <span class="ui-g-8 free-text-value">{{
                              monLog.reviewCompletedDt | date : "MM/dd/yyyy hh:mm:ss a"
                            }}</span>
                          }
                          @if (monLog.reviewer) {
                            <span class="ui-g-4 label"
                              >{{ "MONLOG.LABEL.REVIEWER" | translate }}:</span
                              >
                            }
                            @if (monLog.reviewer) {
                              <span class="ui-g-8 free-text-value">{{
                                monLog.reviewer
                              }}</span>
                            }
                          </div>
                        }
                        <br />
                      </div>
                      @if (monLog.id) {
                        <div class="ui-g-12 no-padding-top-bottom">
                          <h5>{{ "MONLOG.LABEL.CALL_INFORMATION" | translate }}</h5>
                          <div class="ui-g-12 no-padding-top-bottom">
                            <span class="ui-g-4 label"
                              >{{ "MONLOG.LABEL.TYPE_OF_CALL" | translate }}:</span
                              >
                              <span class="ui-g-8">{{ monLog?.callType }}</span>
                            </div>
                            @if (monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE') {
                              <div
                                class="ui-g-12 no-padding-top-bottom"
                                >
                                <span class="ui-g-4 label"
                                  >{{ "MONLOG.LABEL.CUSTOMER_OUTAGE" | translate }}:</span
                                  >
                                  <span class="ui-g-8">{{ monLog?.customerOutage }}</span>
                                </div>
                              }
                              @if (monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE') {
                                <div
                                  class="ui-g-12 no-padding-top-bottom"
                                  >
                                  <span class="ui-g-4 label"
                                    >{{ "MONLOG.LABEL.CALLOUT_REQUIRED" | translate }}:</span
                                    >
                                    <span class="ui-g-8">{{ monLog?.calloutRequired }}</span>
                                  </div>
                                }
                                @if (monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE') {
                                  <div
                                    class="ui-g-12 no-padding-top-bottom"
                                    >
                                    <span class="ui-g-4 label"
                                      >{{ "MONLOG.LABEL.ONE_CALL_NO" | translate }}:</span
                                      >
                                      <span class="ui-g-8">{{ monLog?.oneCallNumber }}</span>
                                    </div>
                                  }
                                  @if (monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE') {
                                    <div
                                      class="ui-g-12 no-padding-top-bottom"
                                      >
                                      <span class="ui-g-4 label"
                                        >{{ "MONLOG.LABEL.TICKET_NUMBER" | translate }}:</span
                                        >
                                        <span class="ui-g-8">{{ monLog?.ticketNumber }} </span>
                                      </div>
                                    }
                                    @if (monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE') {
                                      <div
                                        class="ui-g-12 no-padding-top-bottom"
                                        >
                                        <span class="ui-g-4 label"
                                          >{{ "MONLOG.LABEL.TIME_BEGAN" | translate }}:</span
                                          >
                                          <span class="ui-g-8">
                                            {{ monLog?.timeBegan | date : "hh:mm:ss a" }}</span
                                            >
                                          </div>
                                        }
                                        @if (monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE') {
                                          <div
                                            class="ui-g-12 no-padding-top-bottom"
                                            >
                                            <span class="ui-g-4 label"
                                              >{{ "MONLOG.LABEL.TIME_ON_SITE" | translate }}:</span
                                              >
                                              <span class="ui-g-8">
                                                {{ monLog?.timeOnSite | date : "hh:mm:ss a" }}</span
                                                >
                                              </div>
                                            }
                                            @if (monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE') {
                                              <div
                                                class="ui-g-12 no-padding-top-bottom"
                                                >
                                                <span class="ui-g-4 label"
                                                  >{{ "MONLOG.LABEL.FALSE_ALARM" | translate }}:</span
                                                  >
                                                  <span class="ui-g-8"> {{ monLog?.falseAlarm }}</span>
                                                </div>
                                              }
                                              @if (showAEWorkTypes === true) {
                                                <div
                                                  class="ui-g-12 no-padding-top-bottom"
                                                  >
                                                  <span class="ui-g-4 label"
                                                    >{{ "MONLOG.LABEL.WORK_TYPE" | translate }}:</span
                                                    >
                                                    <span class="ui-g-8"> {{ workType }}</span>
                                                  </div>
                                                }
                                                @if (
                                                  showMRWorkTypes === true &&
                                                  (monLog.logCategory === 'MR' || monLog.logCategory === 'AE')
                                                  ) {
                                                  <div
                                                    class="ui-g-12 no-padding-top-bottom"
                                                    >
                                                    <span class="ui-g-4 label"
                                                      >{{ "MONLOG.LABEL.WORK_TYPE" | translate }}:</span
                                                      >
                                                      <span class="ui-g-8"> {{ workType }}</span>
                                                    </div>
                                                  }
                                                  @if (monLog.logCategory === 'MR' || monLog.logCategory === 'AE') {
                                                    <div
                                                      class="ui-g-12 no-padding-top-bottom"
                                                      >
                                                      <span class="ui-g-4 label"
                                                        >{{ "MONLOG.LABEL.BACK_IN_SERVICE" | translate }}:</span
                                                        >
                                                        <span class="ui-g-8">{{ monLog?.backInService }}</span>
                                                      </div>
                                                    }
                                                    @if (monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE') {
                                                      <div
                                                        class="ui-g-12 no-padding-top-bottom"
                                                        >
                                                        <span class="ui-g-4 label"
                                                          >{{ "MONLOG.LABEL.GAS_RELEASE_TYPE" | translate }}:</span
                                                          >
                                                          <span class="ui-g-8"> {{ monLog?.gasReleaseType }}</span>
                                                        </div>
                                                      }
                                                      @if (
                                                        showSeverity === true &&
                                                        monLog.logCategory !== 'MR' &&
                                                        monLog.logCategory !== 'AE'
                                                        ) {
                                                        <div
                                                          class="ui-g-12 no-padding-top-bottom"
                                                          >
                                                          <span class="ui-g-4 label"
                                                            >{{ "MONLOG.LABEL.SEVERITY" | translate }}:</span
                                                            >
                                                            <span class="ui-g-8"> {{ monLog?.commFailSeverity }}</span>
                                                          </div>
                                                        }
                                                        @if (
                                                          showNotam === true &&
                                                          monLog.logCategory !== 'MR' &&
                                                          monLog.logCategory !== 'AE'
                                                          ) {
                                                          <div
                                                            class="ui-g-12 no-padding-top-bottom"
                                                            >
                                                            <span class="ui-g-4 label"
                                                              >{{ "MONLOG.LABEL.NOTAM" | translate }}:</span
                                                              >
                                                              <span class="ui-g-8"> {{ monLog?.notam }}</span>
                                                            </div>
                                                          }
                                                          @if (
                                                            showNotam === true &&
                                                            monLog.logCategory !== 'MR' &&
                                                            monLog.logCategory !== 'AE'
                                                            ) {
                                                            <div
                                                              class="ui-g-12 no-padding-top-bottom"
                                                              >
                                                              <span class="ui-g-4 label"
                                                                >{{ "MONLOG.LABEL.EXPIRE_DT" | translate }}:</span
                                                                >
                                                                <span class="ui-g-8">
                                                                  {{
                                                                  monLog?.expireDt | date : "MM/dd/yyyy hh:mm:ss a"
                                                                  }}</span
                                                                  >
                                                                </div>
                                                              }
                                                            </div>
                                                          }
                                                          @if (monLog.id) {
                                                            <div class="ui-g-12 no-padding-top-bottom">
                                                              <h5>{{ "MONLOG.LABEL.CALLER_LOCATION_INFO" | translate }}</h5>
                                                              @if (monLog.logCategory === 'N') {
                                                                <div
                                                                  class="ui-g-12 no-padding-top-bottom no-padding"
                                                                  >
                                                                  <div class="ui-g-12 no-padding-top-bottom">
                                                                    <span class="ui-g-4 label"
                                                                      >{{ "CONTACT.LABEL.FIRST_NAME" | translate }}:</span
                                                                      >
                                                                      <span class="ui-g-8">{{
                                                                        monLog?.firstName
                                                                      }}</span>
                                                                    </div>
                                                                    <div class="ui-g-12 no-padding-top-bottom">
                                                                      <span class="ui-g-4 label"
                                                                        >{{ "CONTACT.LABEL.LAST_NAME" | translate }}:</span
                                                                        >
                                                                        <span class="ui-g-8">{{
                                                                          monLog?.lastName
                                                                        }}</span>
                                                                      </div>
                                                                      <div class="ui-g-12 no-padding-top-bottom">
                                                                        <span class="ui-g-4 label"
                                                                          >{{ "MONLOG.LABEL.PHONE" | translate }}:</span
                                                                          >
                                                                          <span class="ui-g-8">{{
                                                                            monLog?.phone
                                                                          }}</span>
                                                                        </div>
                                                                        <div class="ui-g-12 no-padding-top-bottom no-padding">
                                                                          <div class="ui-g-3 ui-xl-3 ui-lg-4">
                                                                            <span class="ui-g-4 label"
                                                                              >{{ "MONLOG.LABEL.STREET" | translate }}:</span
                                                                              >
                                                                              <span class="ui-g-8">{{
                                                                                monLog?.street
                                                                              }}</span>
                                                                            </div>
                                                                          </div>
                                                                          <div class="ui-g-12 no-padding-top-bottom no-padding">
                                                                            <div class="ui-g-12 no-padding-top-bottom">
                                                                              <span class="ui-g-4 label"
                                                                                >{{ "LOCATION.LABEL.STATE" | translate }}:</span
                                                                                >
                                                                                <span class="ui-g-8">{{
                                                                                  monLog?.state
                                                                                }}</span>
                                                                              </div>
                                                                              <div class="ui-g-12 no-padding-top-bottom">
                                                                                <span class="ui-g-4 label"
                                                                                  >{{ "LOCATION.LABEL.COUNTY" | translate }}:</span
                                                                                  >
                                                                                  <span class="ui-g-8">{{
                                                                                    monLog?.county
                                                                                  }}</span>
                                                                                </div>
                                                                                <div class="ui-g-12 no-padding-top-bottom">
                                                                                  <span class="ui-g-4 label"
                                                                                    >{{ "LOCATION.LABEL.TOWNSHIP" | translate }}:</span
                                                                                    >
                                                                                    <span class="ui-g-8">{{
                                                                                      monLog?.township
                                                                                    }}</span>
                                                                                  </div>
                                                                                  <div class="ui-g-12 no-padding-top-bottom">
                                                                                    <span class="ui-g-4 label"
                                                                                      >{{ "LOCATION.LABEL.CITY" | translate }}:</span
                                                                                      >
                                                                                      <span class="ui-g-8">{{
                                                                                        monLog?.city
                                                                                      }}</span>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              }
                                                                            </div>
                                                                          }
                                                                          @if (
                                                                            monLog.logCategory === 'I' ||
                                                                            monLog.logCategory === 'MR' ||
                                                                            monLog.logCategory === 'AE'
                                                                            ) {
                                                                            <div
                                                                              class="ui-g-12 no-padding-top-bottom no-padding"
                                                                              >
                                                                              <div class="ui-g-12 no-padding-top-bottom">
                                                                                <span class="ui-g-4 label"
                                                                                  >{{ "MONLOG.LABEL.INTERNAL_CONTACT_ONE" | translate }}:</span
                                                                                  >
                                                                                  <span class="ui-g-8">{{
                                                                                    monLog?.internalOne?.fullName
                                                                                  }}</span>
                                                                                </div>
                                                                                <div class="ui-g-12 no-padding-top-bottom">
                                                                                  <span class="ui-g-4 label"
                                                                                    >{{ "MONLOG.LABEL.INTERNAL_CONTACT_TWO" | translate }}:</span
                                                                                    >
                                                                                    <span class="ui-g-8">{{
                                                                                      monLog?.internalTwo?.fullName
                                                                                    }}</span>
                                                                                  </div>
                                                                                </div>
                                                                              }
                                                                              @if (monLog.id) {
                                                                                <div class="ui-g-12 no-padding-top-bottom">
                                                                                  <h5>{{ "MONLOG.LABEL.FACILITY_INFO" | translate }}</h5>
                                                                                  <div class="ui-g-12 no-padding-top-bottom">
                                                                                    <span class="ui-g-4 label"
                                                                                      >{{ "LOCATION.LABEL.FACILITY_TYPE" | translate }}:</span
                                                                                      >
                                                                                      <span class="ui-g-8">{{
                                                                                        monLog?.Facility.facilityType
                                                                                      }}</span>
                                                                                    </div>
                                                                                    <div class="ui-g-12 no-padding-top-bottom">
                                                                                      <span class="ui-g-4 label"
                                                                                        >{{ "LOCATION.LABEL.FACILITY_NAME" | translate }}:</span
                                                                                        >
                                                                                        <span class="ui-g-8">{{
                                                                                          monLog?.Facility.facilityName
                                                                                        }}</span>
                                                                                      </div>
                                                                                      <div class="ui-g-12 no-padding-top-bottom">
                                                                                        <span class="ui-g-4 label"
                                                                                          >{{ "LOCATION.LABEL.FACILITY_UID" | translate }}:</span
                                                                                          >
                                                                                          <span class="ui-g-8">{{
                                                                                            monLog?.Facility.facilityUid
                                                                                          }}</span>
                                                                                        </div>
                                                                                        <div class="ui-g-12 no-padding-top-bottom">
                                                                                          <span class="ui-g-4 label"
                                                                                            >{{ "MONLOG.LABEL.PIPELINE" | translate }}:</span
                                                                                            >
                                                                                            <span class="ui-g-8">{{
                                                                                              monLog?.pipeline
                                                                                            }}</span>
                                                                                          </div>
                                                                                          <div class="ui-g-12 no-padding-top-bottom">
                                                                                            <span class="ui-g-4 label"
                                                                                              >{{ "LOCATION.LABEL.AOR" | translate }}:</span
                                                                                              >
                                                                                              <span class="ui-g-8">{{
                                                                                                monLog?.Facility.aor
                                                                                              }}</span>
                                                                                            </div>
                                                                                            <div class="ui-g-12 no-padding-top-bottom">
                                                                                              <span class="ui-g-4 label"
                                                                                                >{{ "MONLOG.LABEL.FACILITY_COMPANY" | translate }}:</span
                                                                                                >
                                                                                                <span class="ui-g-8">{{
                                                                                                  monLog?.Facility.company
                                                                                                }}</span>
                                                                                              </div>
                                                                                            </div>
                                                                                          }
                                                                                          @if (monLog.id) {
                                                                                            <div class="ui-g-12 no-padding-top-bottom">
                                                                                              <h5>{{ "MONLOG.LABEL.PRIMARY_CONTACT_INFO" | translate }}</h5>
                                                                                              @for (contact of monLog.MonLogContacts; track contact; let i = $index) {
                                                                                                <div
                                                                                                  class="ui-g-12 no-padding-top-bottom"
                                                                                                  >
                                                                                                  <div>
                                                                                                    <div class="ui-g-12 no-padding-top-bottom">
                                                                                                      @if (i === 0) {
                                                                                                        <span
                                                                                                          class="ui-g-3 ui-xl-3 ui-lg-4 label"
                                                                                                          [translate]="'MONLOG.LABEL.PRIMARY_CONTACT'"
                                                                                                        ></span>
                                                                                                      }
                                                                                                      @if (i !== 0) {
                                                                                                        <span
                                                                                                          class="ui-g-3 ui-xl-3 ui-lg-4 label"
                                                                                                          [translate]="'MONLOG.LABEL.OTHER_CONTACT'"
                                                                                                          [translateParams]="{ index: i }"
                                                                                                        ></span>
                                                                                                      }
                                                                                                      <span class="ui-g-4 ui-xl-4 ui-lg-5">
                                                                                                        {{ contact.primaryFullName }}</span
                                                                                                        >
                                                                                                      </div>
                                                                                                      <div class="ui-g-12 no-padding-top-bottom">
                                                                                                        @if (i === 0) {
                                                                                                          <span
                                                                                                            class="ui-g-3 ui-xl-3 ui-lg-4 label"
                                                                                                            [translate]="'MONLOG.LABEL.SUPERVISOR'"
                                                                                                          ></span>
                                                                                                        }
                                                                                                        @if (i !== 0) {
                                                                                                          <span
                                                                                                            class="ui-g-3 ui-xl-3 ui-lg-4 label"
                                                                                                            [translate]="'MONLOG.LABEL.OTHER_SUPERVISOR'"
                                                                                                            [translateParams]="{ index: i }"
                                                                                                          ></span>
                                                                                                        }
                                                                                                        <span class="ui-g-4 ui-xl-4 ui-lg-5">
                                                                                                          {{ contact.supervisorFullName }}</span
                                                                                                          >
                                                                                                        </div>
                                                                                                        <div class="ui-g-12 no-padding-top-bottom">
                                                                                                          @if (i === 0) {
                                                                                                            <span
                                                                                                              class="ui-g-3 ui-xl-3 ui-lg-4 label"
                                                                                                              [translate]="'MONLOG.LABEL.MANAGER'"
                                                                                                            ></span>
                                                                                                          }
                                                                                                          @if (i !== 0) {
                                                                                                            <span
                                                                                                              class="ui-g-3 ui-xl-3 ui-lg-4 label"
                                                                                                              [translate]="'MONLOG.LABEL.OTHER_MANAGER'"
                                                                                                              [translateParams]="{ index: i }"
                                                                                                            ></span>
                                                                                                          }
                                                                                                          <span class="ui-g-4 ui-xl-4 ui-lg-5">
                                                                                                            {{ contact.managerFullName }}</span
                                                                                                            >
                                                                                                          </div>
                                                                                                        </div>
                                                                                                      </div>
                                                                                                    }
                                                                                                  </div>
                                                                                                }
                                                                                                @if (monLog.id) {
                                                                                                  <div class="ui-g-12 no-padding-top-bottom">
                                                                                                    <h5>{{ "MONLOG.LABEL.ENTRIES" | translate }}</h5>
                                                                                                    @for (entry of monLog.MonLogAlarms; track entry) {
                                                                                                      <div
                                                                                                        class="ui-g-12 no-padding-top-bottom"
                                                                                                        >
                                                                                                        <strong
                                                                                                          >{{ entry.time | date : "MM/dd/yyyy hh:mm:ss a" }} -
                                                                                                          {{ entry.user }}:</strong
                                                                                                          >
                                                                                                          <span [innerHTML]="entry.note" class="printMeToo"></span>
                                                                                                        </div>
                                                                                                      }
                                                                                                    </div>
                                                                                                  }
                                                                                                  @if (monLog.id) {
                                                                                                    <div class="ui-g-12 no-padding-top-bottom">
                                                                                                      <h5>{{ "MONLOG.LABEL.ACTIONS_TAKEN" | translate }}</h5>
                                                                                                      @for (entry of monLog.MonLogEntries; track entry) {
                                                                                                        <div
                                                                                                          class="ui-g-12 no-padding-top-bottom"
                                                                                                          >
                                                                                                          <strong
                                                                                                            >{{ entry.time | date : "MM/dd/yyyy hh:mm:ss a" }} -
                                                                                                            {{ entry.user }}:</strong
                                                                                                            >
                                                                                                            <span [innerHTML]="entry.note" class="printMeToo"></span>
                                                                                                          </div>
                                                                                                        }
                                                                                                      </div>
                                                                                                    }
                                                                                                    @if (monLog.id) {
                                                                                                      <div class="ui-g-12 no-padding-top-bottom">
                                                                                                        <h5>{{ "MONLOG.LABEL.NOTIFICATIONS_AND_AUDIT" | translate }}</h5>
                                                                                                        @for (a of monLog.MonLogNotifications; track a) {
                                                                                                          <div
                                                                                                            class="ui-g-12 no-padding-top-bottom"
                                                                                                            >
                                                                                                            <strong
                                                                                                              >{{ a.time | date : "yyyy-MM-dd HH:mm:ss" }} - {{ a.user }}:</strong
                                                                                                              >
                                                                                                              {{ a.note }}
                                                                                                            </div>
                                                                                                          }
                                                                                                          <br />
                                                                                                          <br />
                                                                                                          @for (a of monLog.MonLogAudits; track a) {
                                                                                                            <div
                                                                                                              class="ui-g-12 no-padding-top-bottom"
                                                                                                              >
                                                                                                              <strong
                                                                                                                >{{ a.time | date : "yyyy-MM-dd HH:mm:ss" }} - {{ a.user }}:</strong
                                                                                                                >
                                                                                                                {{ a.note }}
                                                                                                              </div>
                                                                                                            }
                                                                                                          </div>
                                                                                                        }
                                                                                                        @if (monLog.id) {
                                                                                                          <div class="ui-g-12 no-padding-top-bottom">
                                                                                                            <h5>{{ "MONLOG.LABEL.CALLOUT_INFORMATION" | translate }}</h5>
                                                                                                            @for (callout of monLog.MonLogCallouts; track callout; let j = $index) {
                                                                                                              <div
                                                                                                                class="ui-g-12 no-padding-top-bottom"
                                                                                                                >
                                                                                                                <div>
                                                                                                                  <div
                                                                                                                    class="ui-g-12 no-padding-top-bottom"
                                                                                                                    style="border-bottom: 1px solid #dedede"
                                                                                                                    >
                                                                                                                    <span class="ui-g-3 label" style="padding-top: 1.5em !important">
                                                                                                                      {{ callout.firstName }} {{ callout.lastName }}
                                                                                                                    </span>
                                                                                                                    <span class="ui-g-3">
                                                                                                                      <span class="ui-g-12 no-padding-top-bottom">
                                                                                                                        {{ callout.startDt | date : "MM/dd/yyyy hh:mm:ss a" }}</span
                                                                                                                        >
                                                                                                                      </span>
                                                                                                                      <span class="ui-g-3">
                                                                                                                        <span class="ui-g-12 no-padding-top-bottom">
                                                                                                                          {{ callout.endDt | date : "MM/dd/yyyy hh:mm:ss a" }}</span
                                                                                                                          >
                                                                                                                        </span>
                                                                                                                      </div>
                                                                                                                    </div>
                                                                                                                  </div>
                                                                                                                }
                                                                                                              </div>
                                                                                                            }
                                                                                                          </div>
                                                                                                        </div>
                                                                                                      }
