<app-progress-spinner [enabled]="!shiftTurnover"></app-progress-spinner>
<p-blockUI [blocked]="saving === true || loading === true"></p-blockUI>
@if (form) {
  <form [formGroup]="form" id="shiftturnover-create-form">
    <div class="xtra-small-top-padding" id="contact-action-buttons-select">
      <div class="no-padding-top-bottom" id="contact-form-controls-buttons">
        @if (shiftTurnover?.id && shouldShowEditSaveButtons()) {
          <div
            class="actions-dropdown"
            >
            <span class="label">{{ "COMMON.LABEL.ACTIONS" | translate }}:</span>
            <span class="no-padding-top-bottom">
              <p-dropdown
                [options]="actions"
                (onChange)="actionEvent($event, dd)"
                [(ngModel)]="selectedAction"
                [ngModelOptions]="{ standalone: true }"
                scrollHeight="calc(100vh - 35vh)"
                styleclass="action-drop-down"
                #dd
              ></p-dropdown>
            </span>
          </div>
        }
        @if (!isEditting && shouldShowEditSaveButtons()) {
          <div
            class="contact-button"
            >
            <button
              pButton
              label="{{ 'COMMON.LABEL.BUTTONS.EDIT' | translate }}"
              icon="fa fa-fw fa-edit"
              (click)="setEditting()"
            ></button>
          </div>
        }
        @if (isEditting && shouldShowEditSaveButtons()) {
          <div
            class="contact-button"
            >
            <button
              pButton
              label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
              icon="fa fa-fw fa-save"
              [disabled]="!this.form.valid || this.saving"
              (click)="saveShiftTurnover()"
            ></button>
          </div>
        }
        <div class="contact-button">
          <button
            pButton
            class="refresh-button"
            icon="fa fa-fw fa-refresh"
            (click)="refresh()"
          ></button>
        </div>
        @if (saving) {
          <div>
            <app-progress-spinner [enabled]="saving"></app-progress-spinner>
          </div>
        }
      </div>
      <div class="row">
        <div class="no-padding-top-bottom row-item">
          <span class="label" style="width: 96px"
            >{{ "CRM.LABEL.SHIFT_CHANGE" | translate }}:</span
            >
            <span class="row-input">
              <p-dropdown
                [options]="shiftTypes"
                formControlName="shiftType"
                scrollHeight="calc(100vh - 35vh)"
              ></p-dropdown>
            </span>
          </div>
          <div class="no-padding-top-bottom row-item">
            <span class="label" style="width: 96px"
              >{{ "CRM.LABEL.CREATED_DATE" | translate }}:</span
              >
              <span class="row-input">
                <p-calendar
                  formControlName="createdDt"
                  [showTime]="true"
                  [showSeconds]="true"
                  hourFormat="12"
                ></p-calendar>
              </span>
            </div>
            <div class="row-item">
              @if (shiftTurnover.departingCompletedDate) {
                <div>
                  <span class="date-label"
                    >{{ "CRM.LABEL.DEPARTING_COMPLETED_DATE" | translate }}:</span
                    >
                    <span>{{
                      shiftTurnover.departingCompletedDate
                      | date : "MM/dd/yyyy hh:mm:ss a"
                    }}</span>
                  </div>
                }
              </div>
            </div>
            <div class="row">
              <div
                class="no-padding-top-bottom row-item"
                style="display: flex; align-items: center; height: 40px"
                >
                <span class="label">{{ "CRM.LABEL.TURNOVER_DATE" | translate }}:</span>
                <span class="row-input">
                  <p-calendar formControlName="shiftDate"></p-calendar>
                </span>
              </div>
              <div
                class="no-padding-top-bottom row-item"
                style="display: flex; align-items: center; height: 40px"
                >
                <span class="label">{{ "CRM.LABEL.STATUS" | translate }}:</span>
                <span class="row-input">
                  <input pInputText formControlName="status" />
                </span>
              </div>
              <div class="row-item">
                @if (shiftTurnover.incomingCompletedDate) {
                  <div>
                    <span class="date-label"
                      >{{ "CRM.LABEL.INCOMING_COMPLETED_DATE" | translate }}:</span
                      >
                      <span>{{
                        shiftTurnover.incomingCompletedDate | date : "MM/dd/yyyy hh:mm:ss a"
                      }}</span>
                    </div>
                  }
                </div>
              </div>
              <div class="row">
                <div
                  class="no-padding-top-bottom row-item"
                  style="display: flex; align-items: center; height: 40px"
                  >
                  <span class="label"
                    >{{ "CRM.LABEL.DEPARTING_ANALYST" | translate }}:</span
                    >
                    <span class="row-input">
                      @if (!contactsLoading) {
                        <p-autoComplete
                          [suggestions]="filteredContacts"
                          formControlName="departingAnalyst"
                          [dropdown]="true"
                          field="fullName"
                          (completeMethod)="contactSearch($event)"
                          appendTo="body"
                          [forceSelection]="true"
                          >
                        </p-autoComplete>
                      }
                      @if (contactsLoading) {
                        <p-progressSpinner
                          [style]="{ width: '50px', height: '50px' }"
                        ></p-progressSpinner>
                      }
                    </span>
                  </div>
                  <div
                    class="no-padding-top-bottom row-item"
                    style="display: flex; align-items: center; height: 40px"
                    >
                    <span class="label"
                      >{{ "CRM.LABEL.INCOMING_ANALYST" | translate }}:</span
                      >
                      <span class="row-input">
                        @if (!contactsLoading) {
                          <p-autoComplete
                            [suggestions]="filteredContacts"
                            formControlName="incomingAnalyst"
                            [dropdown]="true"
                            field="fullName"
                            (completeMethod)="contactSearch($event)"
                            appendTo="body"
                            [forceSelection]="true"
                            >
                          </p-autoComplete>
                        }
                        @if (contactsLoading) {
                          <p-progressSpinner
                            [style]="{ width: '50px', height: '50px' }"
                          ></p-progressSpinner>
                        }
                      </span>
                    </div>
                    <div class="row-item"></div>
                  </div>
                </div>
                <div class="break"></div>
                <div class="no-padding">
                  @if (shiftTurnover.ShiftDetails.length > 0) {
                    <div
                      class="no-padding-top-bottom items-container"
                      >
                      <div class="one-sixth"></div>
                      <div class="one-third text-left">
                        {{ "CRM.LABEL.DEPARTING_ANALYST" | translate }}:
                      </div>
                      @if (shiftTurnover.status !== 'N') {
                        <div class="one-third text-left">
                          {{ "CRM.LABEL.INCOMING_ANALYST" | translate }}:
                        </div>
                      }
                      <div class="break"></div>
                      @for (item of getChildControls('items'); track item; let j = $index) {
                        <div
                          class="no-padding-top-bottom items-container w-100"
                          formArrayName="items"
                          >
                          <div formGroupName="{{ j }}" class="item-row w-100">
                            <div class="no-padding-top-bottom item-label">
                              {{ item.get("incomingName").value }}:
                            </div>
                            <div class="departing-turnover-flag">
                              <p-toggleButton
                                formControlName="departingTurnoverFlag"
                                onLabel="YES"
                                offLabel="NO"
                              ></p-toggleButton>
                            </div>
                            <div class="departing-comments input-field">
                              <textarea
                                pInputTextarea
                                autoResize="autoResize"
                                formControlName="departingComments"
                                [rows]="2"
                                [cols]="textAreaSize"
                              ></textarea>
                              <app-inline-validation
                                field="departingComments"
                                [control]="getItemFormChild(j, 'departingComments')"
                                [validationMessages]="validationMessages"
                                [validationParams]="validationParams"
                                >
                              </app-inline-validation>
                            </div>
                            @if (shiftTurnover.status !== 'N') {
                              <div
                                class="incoming-turnover-flag"
                                >
                                <p-toggleButton
                                  formControlName="incomingTurnoverFlag"
                                  onLabel="YES"
                                  offLabel="NO"
                                ></p-toggleButton>
                              </div>
                            }
                            @if (shiftTurnover.status !== 'N') {
                              <div
                                class="incoming-comments input-field"
                                >
                                <textarea
                                  pInputTextarea
                                  autoResize="autoResize"
                                  formControlName="incomingComments"
                                  [rows]="2"
                                  [cols]="textAreaSize"
                                ></textarea>
                                <app-inline-validation
                                  field="incomingComments"
                                  [control]="getItemFormChild(j, 'incomingComments')"
                                  [validationMessages]="validationMessages"
                                  [validationParams]="validationParams"
                                  >
                                </app-inline-validation>
                              </div>
                            }
                          </div>
                        </div>
                      }
                      <div class="items-container w-100">
                        <div
                          class="no-padding-top-bottom item-label"
                          [translate]="'CRM.LABEL.ADDITIONAL_COMMENTS'"
                        ></div>
                        <div class="no-padding-top-bottom incoming-comments input-field">
                          <textarea
                            pInputTextarea
                            formControlName="incomingAdditionalComments"
                            autoResize="autoResize"
                            [rows]="2"
                            [cols]="textAreaSize"
                            style="margin-left: 10.2vw"
                          ></textarea>
                          <app-inline-validation
                            field="incomingAdditionalComments"
                            [control]="getBaseFormChild('incomingAdditionalComments')"
                            [validationMessages]="validationMessages"
                            [validationParams]="validationParams"
                            >
                          </app-inline-validation>
                        </div>
                        @if (shiftTurnover.status !== 'N') {
                          <div
                            class="no-padding-top-bottom departing-comments input-field"
                            >
                            <textarea
                              pInputTextarea
                              autoResize="autoResize"
                              formControlName="departingAdditionalComments"
                              [rows]="2"
                              [cols]="textAreaSize"
                              style="margin-left: 10.3vw"
                            ></textarea>
                            <app-inline-validation
                              field="departingAdditionalComments"
                              [control]="getBaseFormChild('departingAdditionalComments')"
                              [validationMessages]="validationMessages"
                              [validationParams]="validationParams"
                              >
                            </app-inline-validation>
                          </div>
                        }
                      </div>
                    </div>
                  }
                </div>
                <div class="break"></div>
                @if (shiftTurnover.ShiftDetails.length > 0 && canViewDeparting) {
                  <p-panel
                    header="Departing Analyst"
                    >
                    <div class="departing-analyst">
                      <div class="no-padding-top-bottom mitigations-container">
                        <span
                          class="no-padding-top-bottom departing-label"
                          [translate]="'CRM.LABEL.FIT_TO_DRIVE'"
                          >
                        </span>
                        <span class="no-padding-top-bottom">
                          <p-toggleButton
                            formControlName="departingFitFlag"
                            onLabel="YES"
                            offLabel="NO"
                          ></p-toggleButton>
                        </span>
                      </div>
                      <div class="break"></div>
                      <span
                        class="no-padding-top-bottom departing-label"
                        [translate]="'CRM.LABEL.FATIGUE_MITIGATIONS'"
                        >
                      </span>
                      <div class="break"></div>
                      <div class="no-padding-top-bottom w-100 mitigations-container">
                        @for (item of getChildControls('mitigations'); track item; let j = $index) {
                          <span
                            class="no-padding-top-bottom basis-25"
                            formArrayName="mitigations"
                            >
                            <span formGroupName="{{ j }}" class="mitigations-container">
                              <span class="departing-label basis-20">{{
                                item.get("mitigationName").value
                              }}</span>
                              <span class="basis-5">
                                <p-toggleButton
                                  formControlName="turnoverFlag"
                                  onLabel="YES"
                                  offLabel="NO"
                                ></p-toggleButton>
                              </span>
                            </span>
                          </span>
                        }
                      </div>
                    </div>
                  </p-panel>
                }
                <div class="break"></div>
                @if (
                  shiftTurnover.ShiftDetails.length > 0 &&
                  shiftTurnover.status !== 'N' &&
                  canViewIncoming
                  ) {
                  <p-panel
                    header="Incoming Analyst"
                    >
                    <div class="incoming-analyst">
                      <div class="no-padding-top-bottom mitigations-container">
                        <span
                          class="no-padding-top-bottom incoming-label"
                          [translate]="'CRM.LABEL.MC_FIT_TO_WORK'"
                          >
                        </span>
                        <span class="no-padding-top-bottom">
                          <p-toggleButton
                            formControlName="incomingFitFlag"
                            onLabel="YES"
                            offLabel="NO"
                          ></p-toggleButton>
                        </span>
                      </div>
                    </div>
                    <br />
                  </p-panel>
                }
              </form>
            }

            <app-audit-dialog-container
              [displayDialog]="displayAuditDialog"
              [auditHistory]="history"
              (dialogHiddenEvent)="auditDialogHidden()"
              [loading]="auditLoading"
            ></app-audit-dialog-container>
