import { CommonModule } from '@angular/common';
import { Component, OnInit, signal, inject, input, output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { CLAIMS } from 'src/app/constants/auth-constants';
import { CoreModule } from 'src/app/core/core.module';
import { ControlRoomEventReviewEntry } from 'src/app/model/monlogs/control-room-event-review-entry';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { AuthService } from 'src/app/services/auth.service';
import { MonlogsApiService } from 'src/app/services/monlogs-api.service';

@Component({
  selector: 'app-event-review-entry',
  imports: [TranslateModule, CoreModule, CommonModule],
  templateUrl: './event-review-entry.component.html',
  styleUrl: './event-review-entry.component.scss',
})
export class EventReviewEntryComponent implements OnInit {
  private _authApi = inject(AuthApiService);
  private _auth = inject(AuthService);
  private _confirmationService = inject(ConfirmationService);
  private _monLogApi = inject(MonlogsApiService);
  private _translateService = inject(TranslateService);

  readonly entry = input<ControlRoomEventReviewEntry>(undefined);
  readonly editEvent = output<any>();
  readonly entryDeletedEvent = output<any>();

  canEdit = signal(false);
  canDelete = signal(false);
  entryWorking = signal(false);

  ngOnInit() {
    this.checkPermissions();
  }

  checkPermissions(): void {
    if (
      this._authApi.doesUserHaveClaim(
        CLAIMS.CONTROL_ROOM.CONTROL_ROOM_EVENTS.ENTRIES.DELETE_ALL_CONTROL_ROOM_EVENT_REVIEW_ENTRIES
      ) ||
      (this._authApi.doesUserHaveClaim(
        CLAIMS.CONTROL_ROOM.CONTROL_ROOM_EVENTS.ENTRIES.EDIT_OWN_CONTROL_ROOM_EVENT_REVIEW_ENTRIES
      ) &&
        this.isOwnEntry())
    ) {
      this.canEdit.set(true);
    }

    if (
      this._authApi.doesUserHaveClaim(
        CLAIMS.CONTROL_ROOM.CONTROL_ROOM_EVENTS.ENTRIES.DELETE_ALL_CONTROL_ROOM_EVENT_REVIEW_ENTRIES
      ) ||
      (this._authApi.doesUserHaveClaim(
        CLAIMS.CONTROL_ROOM.CONTROL_ROOM_EVENTS.ENTRIES.DELETE_OWN_CONTROL_ROOM_EVENT_REVIEW_ENTRIES
      ) &&
        this.isOwnEntry())
    ) {
      this.canDelete.set(true);
    }
  }

  isOwnEntry(): boolean {
    const entry = this.entry();
    if (!entry?.createdBy) {
      return false;
    }

    return (
      entry.createdBy === this._auth.getUserName() ||
      this._auth.getEmail().startsWith(entry.createdBy)
    );
  }

  async deleteEntry(entry: ControlRoomEventReviewEntry): Promise<void> {
    const header = await firstValueFrom(
      this._translateService.get('MONLOG.MESSAGES.HEADERS.DELETE_ENTRY')
    );
    const message = await firstValueFrom(
      this._translateService.get('MONLOG.MESSAGES.CONFIRMATION.DELETE_CRER')
    );
    const ok = await firstValueFrom(
      this._translateService.get('COMMON.LABEL.BUTTONS.OK')
    );
    const cancel = await firstValueFrom(
      this._translateService.get('COMMON.LABEL.BUTTONS.CANCEL')
    );
    this._confirmationService.confirm({
      header: header,
      icon: 'fa fa-question-circle',
      acceptVisible: true,
      rejectVisible: true,
      acceptLabel: ok,
      rejectLabel: cancel,
      message: message,
      accept: () => {
        this.entryWorking.set(true);
        this._monLogApi.deleteCREREntry(entry.id).subscribe({
          next: (result) => {
            this.entryWorking.set(false);
            this.entryDeletedEvent.emit(this.entry());
          },
          error: (error) => {
            console.error(error);
            this.entryWorking.set(false);
          },
        });
      },
      reject: () => {},
    });
  }
}
