import { Component, OnInit, input, output } from '@angular/core';
import { MonLogAlarm } from 'src/app/model/monlogs/mon-log-alarm';
import { MonLogEntry } from 'src/app/model/monlogs/mon-log-entry';

@Component({
    selector: 'app-mon-logs-entry',
    templateUrl: './mon-logs-entry.component.html',
    styleUrls: ['./mon-logs-entry.component.scss'],
    standalone: false
})
export class MonLogsEntryComponent {
  readonly entry = input<MonLogAlarm | MonLogEntry>(undefined);
  readonly writeAccess = input(false);
  readonly editEvent = output<any>();
  readonly deleteEvent = output<any>();

  constructor() {}
}
