@if (!this.entryWorking()) {
<section>
  <div class="entry-header">
    {{ entry().updatedAt | date : "yyyy-MM-dd hh:mm:ss aaa" }} &nbsp; &nbsp;
    {{ entry().createdBy }}
    @if (entry().controlRoomInvolved) {
    <span class="cr-involved"
      >- {{ "MONLOG.LABEL.CR_INVOLVED" | translate | uppercase }}</span
    >
    }
    <span class="buttons">
      @if (this.canEdit()){
      <i
        class="fa fa-edit"
        (click)="this.editEvent.emit(entry())"
        title="Edit Entry"
      ></i>
      } @if (this.canDelete()){
      <i
        class="fa fa-trash"
        (click)="this.deleteEntry(entry())"
        title="Delete Entry"
      ></i>
      }
    </span>
  </div>
  <div [innerHTML]="entry().note"></div>
</section>
} @else {
<app-progress-spinner [enabled]="this.entryWorking()"></app-progress-spinner>
}
