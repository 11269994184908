<app-progress-spinner [enabled]="!shiftTurnover"></app-progress-spinner>

@if (shiftTurnover) {
  <div
    id="printMe"
    style="background-color: white; width: 800px">
    <div class="ui-g ui-fluid">
      <div class="ui-g-2">
        <button pButton
          label="Print"
          class='small-margin'
        (click)="print()"></button>
      </div>
      <div class="ui-g-12 no-padding-top-bottom">
        <span class="ui-g-4 label ">{{'CRM.LABEL.SHIFT_CHANGE' | translate}}:</span>
        <span class="ui-g-8 free-text-value ">{{shiftTurnover?.ShiftType?.name}} </span>
      </div>
      <div class="ui-g-12 no-padding-top-bottom">
        <span class="ui-g-4 label ">{{'CRM.LABEL.CREATED_DATE' | translate}}:</span>
        <span class="ui-g-8 free-text-value ">{{shiftTurnover.createdAt | date:'MM/dd/yyyy hh:mm:ss a'}} </span>
      </div>
      <div class="ui-g-12 no-padding-top-bottom">
        <span class="ui-g-4 label ">{{'CRM.LABEL.SHIFT_DATE' | translate}}:</span>
        <span class="ui-g-8 free-text-value ">{{shiftTurnover.shiftDate | date:'MM/dd/yyyy hh:mm:ss a'}} </span>
      </div>
      <div class="ui-g-12 no-padding-top-bottom">
        <span class="ui-g-4 label ">{{'CRM.LABEL.STATUS' | translate}}:</span>
        <span class="ui-g-8 free-text-value ">{{statusValue}} </span>
      </div>
      <div class="ui-g-12 no-padding-top-bottom">
        <span class="ui-g-4 label ">{{'CRM.LABEL.DEPARTING_ANALYST' | translate}}:</span>
        <span class="ui-g-8 free-text-value ">{{departingAnalyst}} </span>
      </div>
      <div class="ui-g-12 no-padding-top-bottom">
        <span class="ui-g-4 label ">{{'CRM.LABEL.INCOMING_ANALYST' | translate}}:</span>
        <span class="ui-g-8 free-text-value ">{{incomingAnalyst}} </span>
      </div>
    </div>
    <div class="ui-g-12 no-padding-top-bottom">
      @if (shiftTurnover.departingCompletedDate) {
        <div class="ui-g-12 no-padding-top-bottom"
          >
          <span class="ui-g-3 ui-xl-6 ui-lg-4 no-padding-top-bottom"
          style="font-weight:700">{{'CRM.LABEL.DEPARTING_COMPLETED_DATE' | translate}}:</span>
          <span
          class="ui-g-9 ui-xl-6 ui-lg-8 no-padding-top-bottom">{{shiftTurnover.departingCompletedDate | date:'MM/dd/yyyy hh:mm:ss a'}}</span>
        </div>
      }
      @if (shiftTurnover.incomingCompletedDate) {
        <div class="ui-g-12 no-padding-top-bottom"
          >
          <span class="ui-g-3 ui-xl-6 ui-lg-4 no-padding-top-bottom"
          style="font-weight:700">{{'CRM.LABEL.INCOMING_COMPLETED_DATE' | translate}}:</span>
          <span
          class="ui-g-9 ui-xl-6 ui-lg-8 no-padding-top-bottom">{{shiftTurnover.incomingCompletedDate | date:'MM/dd/yyyy hh:mm:ss a'}}</span>
        </div>
      }
    </div>
    @if (shiftTurnover.ShiftDetails.length > 0) {
      <div class="no-padding-top-bottom items-container"
        >
        <div class="one-sixth"></div>
        <div class="one-third"
        style="font-weight:700"> {{'CRM.LABEL.DEPARTING_ANALYST' | translate}}: </div>
        @if (shiftTurnover.status !== 'N') {
          <div class="one-third"
            style="font-weight:700"
          > {{'CRM.LABEL.INCOMING_ANALYST' | translate}}: </div>
        }
        <div class="break"></div>
        @for (item of itemsArray; track item; let j = $index) {
          <div class="no-padding-top-bottom items-container"
            style="border-top: thin solid #dedede"
            >
            <div class="item-row">
              <div class="no-padding-top-bottom item-label">
                {{item['incomingName']}}
              </div>
              <div class="departing-turnover-flag">
                {{item['departingTurnoverFlag'] ? 'Yes' : 'No'}}
              </div>
              <div class="departing-comments">
                {{item['departingComments']}}
              </div>
              @if (shiftTurnover.status !== 'N') {
                <div class="incoming-turnover-flag"
                  >
                  {{item['incomingTurnoverFlag'] ? 'Yes' : 'No'}}
                </div>
              }
              @if (shiftTurnover.status !== 'N') {
                <div class="incoming-comments"
                  >
                  {{item['incomingComments']}}
                </div>
              }
            </div>
          </div>
        }
        <div class="item-row">
          <div class="item-label no-padding-top-bottom" [translate]="'CRM.LABEL.ADDITIONAL_COMMENTS'">
          </div>
          <div class="departing-comments">
            {{detailsObj['departingAdditionalComments']}}
          </div>
          <!-- empty div for spacing purposes -->
          <div class="incoming-turnover-flag"></div>
          @if (shiftTurnover.status !== 'N') {
            <div class="incoming-comments">
              {{detailsObj['incomingAdditionalComments']}}
            </div>
          }
        </div>
      </div>
    }
    <div class="ui-g-12"
      id="page-break">
      @if (shiftTurnover.ShiftDetails.length > 0) {
        <p-panel header="{{'CRM.LABEL.DEPARTING_ANALYST' | translate}}"
          >
          <div class="departing-analyst">
            <div class="mitigations-container">
              <span class="no-padding-top-bottom departing-label"
                style="font-weight: 700"
                [translate]="'CRM.LABEL.FIT_TO_DRIVE'">
              </span>
              <span class="no-padding-top-bottom">
                {{detailsObj?.departingFitFlag ? 'Yes': 'No'}}
              </span>
            </div>
            <div class="break"></div>
            <div class="ui-g-12">
              <span class="no-padding-top-bottom departing-label"
                style="font-weight: 700"
                [translate]="'CRM.LABEL.FATIGUE_MITIGATIONS'">
              </span>
              <div class="break"></div>
              <div class="mitigations-container">
                @for (item of mitArray; track item; let j = $index) {
                  <span class="no-padding-top-bottom basis-25"
                    >
                    <span class="mitigations-container">
                      <span class="departing-label basis-20">{{item['mitigationName']}}</span>
                      <span class="basis-5">
                        {{item['turnoverFlag'] ? 'Yes': 'No'}}
                      </span>
                    </span>
                  </span>
                }
              </div>
            </div>
          </div>
        </p-panel>
      }
      <br />
      @if (shiftTurnover.ShiftDetails.length > 0 && shiftTurnover.status !== 'N') {
        <p-panel header="{{'CRM.LABEL.INCOMING_ANALYST' | translate}}"
          >
          <div class="ui-g ui-fluid">
            <div class="ui-g-12">
              <span class=" ui-g-4 no-padding-top-bottom"
                style="font-weight: 700"
                [translate]="'CRM.LABEL.MC_FIT_TO_WORK'">
              </span>
              <span class=" ui-g-2 no-padding-top-bottom">
                {{detailsObj?.incomingFitFlag ? 'Yes': 'No'}}
              </span>
            </div>
          </div>
        </p-panel>
      }
    </div>
  </div>
}
