<div id="shift-turnover-container">
  <p-blockUI [blocked]="loading === true"></p-blockUI>
  <app-progress-spinner [enabled]="init === false"></app-progress-spinner>
  <div
    class="ui-g ui-fluid"
    id="contact-action-buttons"
    [style.display]="init === false ? 'none' : ''"
    >
    <div class="header" id="daily-log-action-bar">
      <div class="no-padding-top-bottom header-item">
        <span class="label">
          <i class="fa fa-fw fa-arrow-left" (click)="previousDay()"></i>
        </span>
        <span class="label">{{ "CRM.LABEL.START_DATE" | translate }}:</span>
        <span>
          <p-calendar
            [(ngModel)]="startDate"
            (onSelect)="dateUpdate()"
          ></p-calendar>
        </span>
        <span class="label" style="text-align: left !important">6:00 AM</span>
      </div>
      <div>
        <button
          pButton
          label="{{ 'CRM.LABEL.BUTTONS.GO_TO_TODAY' | translate }}"
          icon="fa fa-fw fa-calendar"
          (click)="goToToday()"
        ></button>
      </div>
      <div class="no-padding-top-bottom header-item">
        <span class="label">{{ "CRM.LABEL.END_DATE" | translate }}:</span>
        <span>
          <p-calendar
            [(ngModel)]="endDate"
            (onSelect)="dateUpdate(true)"
          ></p-calendar>
        </span>
        <span class="label" style="text-align: left !important">5:59 AM</span>
        <span class="label">
          <i class="fa fa-fw fa-arrow-right" (click)="nextDay()"></i>
        </span>
      </div>
      <div class="button-group">
        @if (canCreate === true) {
          <div class="button-wrapper-2">
            <button
              pButton
              label="{{ 'CRM.LABEL.BUTTONS.NEW_SHIFT_CHANGE' | translate }}"
              icon="fa fa-fw fa-plus-square"
              (click)="newShiftChange()"
            ></button>
          </div>
        }
        <div class="button-wrapper-2">
          <button
            pButton
            label="{{ 'COMMON.LABEL.BUTTONS.CLEAR' | translate }}"
            icon="fa fa-fw fa-eraser"
            (click)="clearScreen()"
          ></button>
        </div>
        <div class="button-wrapper-2">
          <button
            pButton
            class="refresh-button"
            icon="fa fa-fw fa-refresh"
            (click)="refresh()"
          ></button>
        </div>
      </div>
    </div>
  </div>

  <div [style.display]="init === false ? 'none' : ''">
    <app-general-grid
      [values]="elements"
      [columns]="columns"
      [loading]="loading"
      [deviceSize]="getDeviceSize()"
      [rows]="rows"
      [totalRecords]="totalRecords"
      [filterable]="true"
      [screenName]="screenName"
      [lazy]="true"
      (lazyLoad)="lazyLoad($event)"
      [includeExportCSV]="false"
      (changeViewEvent)="changeViewEvent($event)"
      scrollHeight="calc(100vh - 276px)"
      [usePaginator]="true"
      [rowsPerPageOptions]="[10, 20, 50]"
      [alwaysShowPaginator]="true"
      #grid
      >
    </app-general-grid>
  </div>
</div>
