<div id="monlogs-all-container">
  <p-blockUI [blocked]="loading === true"></p-blockUI>
  @if (ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])) {
    <div
      id="contact-action-buttons"
      >
      <div id="monlogs-all-table-header-items">
        <div id="business-units-wrapper">
          <app-business-unit-filter
            (selectedBusinessUnitsChanged)="handleBusinessUnitChange($event)"
            #buFilter
          ></app-business-unit-filter>
        </div>
        @if (actions && actions.length > 0) {
          <div
            class="button-wrapper action-buttons"
            >
            <span style="font-weight: 700; text-align: right; padding-right: 0.25em"
              >{{ "COMMON.LABEL.ACTIONS" | translate }}:</span
              >
              <span class="no-padding-top-bottom" style="text-align: left !important">
                <p-dropdown
                  [options]="actions"
                  (onChange)="actionEvent($event)"
                  [(ngModel)]="selectedAction"
                  [ngModelOptions]="{ standalone: true }"
                  scrollHeight="calc(100vh - 35vh)"
                  styleclass="action-drop-down"
                ></p-dropdown>
              </span>
            </div>
          }
          <div class="button-wrapper ui-input-group">
            <button
              pButton
              class="width-eighty"
              label="{{ 'COMMON.LABEL.BUTTONS.CLEAR' | translate }}"
              icon="fa fa-fw fa-eraser"
              (click)="clearScreen()"
            ></button>
            <button
              pButton
              class="refresh-button width-twenty"
              icon="fa fa-fw fa-refresh"
              (click)="refresh()"
            ></button>
          </div>
        </div>
      </div>
    }
    @if (ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])) {
      <p-panel
        [showHeader]="true"
        header="Filters"
        [toggleable]="true"
        [(collapsed)]="mobileFilterCollapsed"
        >
        <div>
          <div>
            <app-mon-log-mobile-filter
              (mobileFilterEvent)="mobileFilter($event)"
            ></app-mon-log-mobile-filter>
          </div>
        </div>
      </p-panel>
    }

    <app-progress-spinner [enabled]="!elements"></app-progress-spinner>
    @if (!isMobileDevice()) {
      <div [style.display]="!elements ? 'none' : ''">
        <app-general-grid
          [values]="elements"
          [columns]="columns"
          [loading]="loading"
          [deviceSize]="getDeviceSize()"
          [rows]="rows"
          [totalRecords]="totalRecords"
          [filterable]="true"
          [screenName]="screenName"
          [lazy]="true"
          (lazyLoad)="lazyLoad($event)"
          [includeExportCSV]="true"
          [exportFilename]="exportFilename"
          (changeViewEvent)="changeViewEvent($event)"
          scrollHeight="calc(100vh - 256px)"
          [usePaginator]="true"
          [rowsPerPageOptions]="[50, 100, 200]"
          #grid
          >
        </app-general-grid>
      </div>
    }

    @if (isMobileDevice()) {
      <div [style.display]="!elements ? 'none' : ''">
        <app-general-grid
          [values]="elements"
          [columns]="columns"
          [loading]="loading"
          [deviceSize]="getDeviceSize()"
          [rows]="rows"
          [totalRecords]="totalRecords"
          [filterable]="true"
          [screenName]="screenName"
          [lazy]="true"
          (lazyLoad)="lazyLoad($event)"
          [includeExportCSV]="false"
          (changeViewEvent)="changeViewEvent($event)"
          scrollHeight="calc(100vh - 175px)"
          [usePaginator]="true"
          [rowsPerPageOptions]="[50, 100, 200]"
          #grid
          >
        </app-general-grid>
      </div>
    }
  </div>
