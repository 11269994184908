<div
  class="ui-g-12 no-padding-top-bottom"
  id="mon-log-entry"
  style="font-weight: 700"
  >
  {{ entry().time | date : "yyyy-MM-dd hh:mm:ss aaa" }} &nbsp; &nbsp;
  {{ entry().user }}
  @if (entry().isResolution && entry().isResolution === true) {
    <span
      style="color: green"
      >- {{ "MONLOG.LABEL.RESOLUTION" | translate | uppercase }}</span
      >
    }
    @if (writeAccess() === true) {
      <span style="padding-left: 1em">
        <i
          class="fa fa-edit"
          (click)="this.editEvent.emit(entry())"
          title="Edit Entry"
        ></i>
        <i
          class="fa fa-trash"
          (click)="this.deleteEvent.emit(entry())"
          title="Delete Entry"
        ></i>
      </span>
    }
  </div>
  <div class="ui-g-12 no-padding-bottom" [innerHTML]="entry().note"></div>
