import { AfterContentChecked, Component, Input, OnInit, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { take } from 'rxjs/operators';
import { PRINT_TAB_PREFIX } from 'src/app/constants/common.constants';
import {
  A_AND_E_LOG_CATEGORY,
  A_AND_E_MAINTENANCE,
  M_AND_R_LOG_CATEGORY,
  M_AND_R_MAINTENANCE,
} from 'src/app/constants/monlog-constants';
import { DetailsContainer } from 'src/app/core/containers/details-container';
import { TabService } from 'src/app/core/services/tab.service';
import { MonLog } from 'src/app/model/monlogs/mon-log';
import { AuthService } from 'src/app/services/auth.service';
import { ContactApiService } from 'src/app/services/contact-api.service';
import { DeviceService } from 'src/app/services/device.service';
import { FacilityApiService } from 'src/app/services/facility-api.service';
import { FacilityBuilderService } from 'src/app/services/facility-builder.service';
import { FacilityTableService } from 'src/app/services/facility-table.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LocationApiService } from 'src/app/services/location-api.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { MonlogsApiService } from 'src/app/services/monlogs-api.service';
import { MonlogsBuilderService } from 'src/app/services/monlogs-builder.service';
import { MonLogsTableService } from 'src/app/services/monlogs-table.service';
import { isNullOrUndefined } from 'util';

import {
  getMonLogHeader,
  hasMonLogWorkTypes,
  isAnAlarm,
} from '../functions/monlog-functions';

@Component({
    selector: 'app-mon-log-print-container',
    templateUrl: './mon-log-print-container.component.html',
    styleUrls: ['./mon-log-print-container.component.scss'],
    standalone: false
})
export class MonLogPrintContainerComponent
  extends DetailsContainer
  implements OnInit, OnDestroy, AfterContentChecked
{
  protected _translateService: TranslateService;
  protected _deviceService: DeviceService;
  private _router = inject(Router);
  protected _logAndMessage = inject(LogAndMessageService);
  protected _confirmationService: ConfirmationService;
  private _monLogApi = inject(MonlogsApiService);
  private _monLogBuilder = inject(MonlogsBuilderService);
  protected _locationApi: LocationApiService;
  protected _contactApi: ContactApiService;
  protected _facilityApi: FacilityApiService;
  private _activeRoute = inject(ActivatedRoute);
  protected _facilityBuilder = inject(FacilityBuilderService);
  protected _facilityTableService: FacilityTableService;
  private _auth = inject(AuthService);
  private _monLogTableService = inject(MonLogsTableService);
  private _loader = inject(LoadingService);

  monLog: MonLog;
  isAlarm = false;
  showAEWorkTypes = false;
  showMRWorkTypes = false;
  showSeverity = false;
  showNotam = false;
  workType: string;

  constructor() {
    const _translateService = inject(TranslateService);
    const _deviceService = inject(DeviceService);
    const _confirmationService = inject(ConfirmationService);
    const _locationApi = inject(LocationApiService);
    const _contactApi = inject(ContactApiService);
    const _facilityApi = inject(FacilityApiService);
    const _facilityTableService = inject(FacilityTableService);

    super(
      _translateService,
      _deviceService,
      _confirmationService,
      _locationApi,
      _contactApi,
      _facilityTableService,
      _facilityApi
    );
    this._translateService = _translateService;
    this._deviceService = _deviceService;
    this._confirmationService = _confirmationService;
    this._locationApi = _locationApi;
    this._contactApi = _contactApi;
    this._facilityApi = _facilityApi;
    this._facilityTableService = _facilityTableService;

  }

  ngOnInit() {
    this.setupTabClosingSubscription();
    if (this._loader.isLoaded()) {
      this.initializeMonLog();
    } else {
      this._loader.loadingFinishedEvent.pipe(take(1)).subscribe(() => {
        this.initializeMonLog();
      });
    }
  }

  ngOnDestroy(): void {
    if (this.tabClosingSub) {
      this.tabClosingSub.unsubscribe();
    }
  }

  ngAfterContentChecked(): void {
    const activeId = this.id;
    if (
      !isNullOrUndefined(activeId) &&
      !isNullOrUndefined(this.monLog) &&
      this.monLog.id !== activeId &&
      !this.doCheckComplete
    ) {
      this.doCheckComplete = true;
      this._monLogTableService.setSelected(undefined);
      this.initializeMonLog();
    }
  }

  initializeMonLog() {
    const id = this.id;

    if (!id) {
      this.monLog = this._monLogTableService.getSelected();
      // no id and nothing cached, go back to search.
      if (!this.monLog) {
        this._logAndMessage.errorLogOnly('Mon log was null');
        const index = TabService.getInstance().getActiveIndex();
        TabService.getInstance().closeTab(index);
      }
      this.initialize();
    } else {
      this._monLogApi
        .getMonitoringLogWithAlarms(id)
        .pipe(take(1))
        .subscribe(({ data }) => {
          const clone = Object.assign({}, data);
          if (clone && clone.getMonitoringLogWithAlarms) {
            this.monLog = this._monLogBuilder.buildMonLog(
              clone.getMonitoringLogWithAlarms
            );
            this.doCheckComplete = false;
            this.initialize();
          } else {
            // nothing found, go back to search.
            this._logAndMessage.errorLogOnly(
              'Could not find mon log with ID: ' + id
            );
            const index = TabService.getInstance().getActiveIndex();
            TabService.getInstance().closeTab(index);
          }
        });
    }
  }

  initialize() {
    TabService.getInstance().updateActiveTabLabel(
      PRINT_TAB_PREFIX + getMonLogHeader(this.monLog)
    );
    this.checkAndUpdateLogCategory();

    this.isAlarm = isAnAlarm(this.monLog);

    if (hasMonLogWorkTypes(this.monLog)) {
      this.workType = this.monLog.MonLogWorkTypes.map((t) => t.label).join(
        ', '
      );
    }
  }

  checkAndUpdateLogCategory() {
    // these need checked outside of a new log or existing log.
    if (
      this.monLog.MonLogTypes.some((type) => type.label === A_AND_E_MAINTENANCE)
    ) {
      this.showAEWorkTypes = true;
      this.monLog.logCategory = A_AND_E_LOG_CATEGORY;
    }

    if (
      this.monLog.MonLogTypes.some((type) => type.label === M_AND_R_MAINTENANCE)
    ) {
      this.showMRWorkTypes = true;
      this.monLog.logCategory = M_AND_R_LOG_CATEGORY;
    }
  }

  print() {
    // This used to use printjs, but we ran into some issues with lines
    // overlapping each other in the migration to angular 13, so now
    // we use @media print styling to only show what is needed
    // and use the default window.print() to generate the document!
    window.print();
  }
}
