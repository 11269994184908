import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, viewChild, viewChildren as viewChildren_1, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { TabView } from 'primeng/tabview';
import { forkJoin, interval, Observable, Observer, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { MONLOG_DETAILS_CONTAINER } from 'src/app/constants/common.constants';
import {
  A_AND_E_LOG_CATEGORY,
  A_AND_E_MAINTENANCE,
  M_AND_R_LOG_CATEGORY,
  M_AND_R_MAINTENANCE,
  NEW_AE_CALL,
  NEW_CALL,
  NEW_CONTROLLER_CALL,
  NEW_MR_CALL,
  NEW_ONE_CALL,
  SHOW_AE,
  SHOW_MR,
} from 'src/app/constants/monlog-constants';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { TabService } from 'src/app/core/services/tab.service';
import { CanComponentDeactivate } from 'src/app/guards/deactivate.guard';
import { MonLog } from 'src/app/model/monlogs/mon-log';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceService } from 'src/app/services/device.service';
import { LoadingService } from 'src/app/services/loading.service';
import { MonlogsApiService } from 'src/app/services/monlogs-api.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { MonlogsBuilderService } from 'src/app/services/monlogs-builder.service';
import { isNullOrUndefined } from 'util';
import { getMonLogColumns } from '../definitions/mon-log-table-definition';
import { MonlogsDetailContainerComponent } from './monlogs-detail-container.component';
import { MonLogsTableService } from 'src/app/services/monlogs-table.service';
import { BusinessUnitFilterComponent } from 'src/app/core/components/business-unit-filter/business-unit-filter.component';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { MonLogsAddEntryComponent } from '../components/mon-logs-add-entry.component';
import { CLAIMS } from 'src/app/constants/auth-constants';

@Component({
    selector: 'app-monlogs-search-container',
    templateUrl: './monlogs-search-container.component.html',
    styleUrls: ['./monlogs-search-container.component.scss'],
    standalone: false
})
export class MonlogsSearchContainerComponent
  extends SearchContainerComponent<MonLog>
  implements OnInit, OnDestroy, CanComponentDeactivate
{
  private _translateService = inject(TranslateService);
  protected _deviceService: DeviceService;
  private _monLogsApi = inject(MonlogsApiService);
  private _monLogBuilder = inject(MonlogsBuilderService);
  private _mongLogTableService = inject(MonLogsTableService);
  private _authApi = inject(AuthApiService);
  protected _cdRef: ChangeDetectorRef;
  protected _auth: AuthService;
  protected _loader: LoadingService;
  private _confirmationService = inject(ConfirmationService);
  private _logAndMessage = inject(LogAndMessageService);

  readonly tabs = viewChild<TabView>('tabs');
  readonly viewChildren = viewChildren_1(MonlogsDetailContainerComponent);
  readonly businessUnitFilter = viewChild<BusinessUnitFilterComponent>('buFilter');
  readonly addEntryComponent = viewChild(MonLogsAddEntryComponent);
  allLogs: any[];
  loading: boolean;
  logsLoading: boolean;
  availableCompanies: any[];
  logsLoadingEvent = new EventEmitter<any>();
  hideAE = true;
  hideMR = true;
  lastLazyEvent: any;
  canEdit = false;
  canCreate = false;
  isOneCall = false;
  additionalFilters: SelectItem[] = [];
  actions: any[] = [];
  selectedAction: any;
  statusOptions: SelectItem[];
  types: SelectItem[];
  selectedFilters: string[] = [];
  exportFilename: string;
  activeTabChangedSub: Subscription;
  private _isRefreshing = false;
  refreshSubscription: Subscription;

  constructor() {
    const _deviceService = inject(DeviceService);
    const _cdRef = inject(ChangeDetectorRef);
    const _auth = inject(AuthService);
    const _loader = inject(LoadingService);

    super(_deviceService, _loader, _auth, _cdRef);
    this._deviceService = _deviceService;
    this._cdRef = _cdRef;
    this._auth = _auth;
    this._loader = _loader;

  }

  ngOnInit() {
    this.setupActiveTabChangedSubscription();
    this.setTableService(this._mongLogTableService);
    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.pipe(take(1)).subscribe(() => {
        this.initialize();
      });
    }

    const refreshInterval = interval(60000);
    this.refreshSubscription = refreshInterval.subscribe(() => {
      this.refresh(false);
    });
  }

  initialize() {
    const showAE$ = this._translateService.get('MONLOG.LABEL.SHOW_AE');
    const showMR$ = this._translateService.get('MONLOG.LABEL.SHOW_MR');
    const exportFilename$ = this._translateService.get(
      'MONLOG.EXPORT_FILE_NAME.ACTIVE_LOGS'
    );

    forkJoin([showAE$, showMR$, exportFilename$])
      .pipe(take(1))
      .subscribe((messages) => {
        this.exportFilename = messages[2];
        messages.splice(2);
        const array = messages.map((m) => ({ label: m, value: m }));
        this.additionalFilters = [...array];
      });
    this.statusOptions = this._mongLogTableService.getStatusOptions();
    this.columns = getMonLogColumns(this.statusOptions);
    this.applyPreviousData();
    this.applyPreviousFilters();

    const savedFilters = this._mongLogTableService.getAdditionalFilterOptions();
    if (!isNullOrUndefined(savedFilters)) {
      this.hideAE = savedFilters.hideAE;
      this.hideMR = savedFilters.hideMR;

      if (!savedFilters.hideMR) {
        this.selectedFilters.push(SHOW_MR);
      }
      if (!savedFilters.hideAE) {
        this.selectedFilters.push(SHOW_AE);
      }
    }

    this._translateService
      .get('MONLOG.SCREEN.ACTIVE_LOGS')
      .subscribe((label) => {
        this.screenName = label;
        TabService.getInstance().updateActiveTabLabel(label);
      });

    this._monLogsApi
      .loadAvailableLogTypes()
      .pipe(take(1))
      .subscribe((data) => {
        const clone = { ...data };
        const types = clone.data.getMonLogTypes.items;
        this.types = types
          .filter((t) => t.enabled === true)
          .map((t) => ({ label: t.name, value: t.id }));
      });

    this.canCreate = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.MONITORING_CENTER.MON_LOGS.CREATE_MON_LOGS,
    ]);
    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.MONITORING_CENTER.MON_LOGS.EDIT_MON_LOGS,
    ]);
    this.isOneCall = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.MONITORING_CENTER.MON_LOGS.EMERGENCY_LOCATE_LOGS.CREATE_EMERGENCY_LOCATE_LOGS,
    ]);
    this.updateActionsList();
  }

  updateActionsList() {
    const selectAction$ = this._translateService.get(
      'MONLOG.LABEL.SELECT_ACTION'
    );
    const newCall$ = this._translateService.get(
      'MONLOG.LABEL.BUTTONS.NEW_CALL'
    );
    const newMRCall$ = this._translateService.get(
      'MONLOG.LABEL.BUTTONS.NEW_MR_CALL'
    );
    const newAECall$ = this._translateService.get(
      'MONLOG.LABEL.BUTTONS.NEW_AE_CALL'
    );
    const newControllerCall$ = this._translateService.get(
      'MONLOG.LABEL.BUTTONS.NEW_CONTROLLER_CALL'
    );
    const newOneCall$ = this._translateService.get(
      'MONLOG.LABEL.BUTTONS.NEW_ONE_CALL'
    );
    const sync$ = this._translateService.get('COMMON.LABEL.SYNC');

    let select,
      newControllerCall,
      newOneCall,
      newCall,
      newMRCall,
      newAECall,
      sync = null;
    forkJoin([
      selectAction$,
      newControllerCall$,
      newOneCall$,
      newCall$,
      newMRCall$,
      newAECall$,
      sync$,
    ])
      .pipe(take(1))
      .subscribe((messages) => {
        select = messages[0];
        newControllerCall = messages[1];
        newOneCall = messages[2];
        newCall = messages[3];
        newMRCall = messages[4];
        newAECall = messages[5];
        sync = messages[6];

        if (this.canCreate) {
          this.actions = [
            { label: select, value: 'select' },
            { label: newControllerCall, value: 'new-controller-call' },
            { label: newOneCall, value: 'new-one-call' },
            { label: newCall, value: 'new-call' },
            { label: newMRCall, value: 'new-mr-call' },
            { label: newAECall, value: 'new-ae-call' },
          ];
        } else if (this.isOneCall) {
          this.actions = [
            { label: select, value: 'select' },
            { label: newOneCall, value: 'new-one-call' },
          ];
        }

        // default actions here: Send to Day Crew, etc.
        this.actions = [...this.actions];
      });
  }

  actionEvent($event, object) {
    if ($event.value) {
      if ($event.value === NEW_CALL) {
        this.newCall('N');
      } else if ($event.value === NEW_MR_CALL) {
        this.newCall('MR');
      } else if ($event.value === NEW_AE_CALL) {
        this.newCall('AE');
      } else if ($event.value === NEW_CONTROLLER_CALL) {
        this.newCall('I');
      } else if ($event.value === NEW_ONE_CALL) {
        this.newCall('O');
      }

      if (!isNullOrUndefined(object)) {
        object.clear(null);
      }
    }

    this._cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.activeTabChangedSub) {
      this.activeTabChangedSub.unsubscribe();
    }
    this.refreshSubscription.unsubscribe();
  }

  newCall(category = 'N') {
    const monLog = this._monLogBuilder.buildNullMonLog(category);
    this._mongLogTableService.setLastScreen('active');
    let typeArray = [];
    let logId = '*New';
    if (category === 'O') {
      typeArray = [
        ...this.types.filter(
          (t) => t.label.toLowerCase() === 'emergency locate'
        ),
      ];
    } else if (category === M_AND_R_LOG_CATEGORY) {
      typeArray = [
        ...this.types.filter(
          (t) => t.label.toLowerCase() === M_AND_R_MAINTENANCE.toLowerCase()
        ),
      ];
      logId = '*New M&R';
    } else if (category === A_AND_E_LOG_CATEGORY) {
      typeArray = [
        ...this.types.filter(
          (t) => t.label.toLowerCase() === A_AND_E_MAINTENANCE.toLowerCase()
        ),
      ];
      logId = '*New A&E';
    }

    monLog.MonLogTypes = typeArray;
    this._mongLogTableService.setSelected(monLog);
    const tab = TabService.getInstance().buildNewTab(
      MONLOG_DETAILS_CONTAINER,
      true
    );
    TabService.getInstance().openTab(tab, true);
  }

  /**
   * Method called when the user hits the clear button.
   */
  clearScreen() {
    this.lazyLoad({ lazy: {} });
    this.grid().resetTable();
    this._mongLogTableService.clearResults();
    this.elements = [];
  }

  /**
   * Method called when a row double click event is fired.  This navigates to the details page.
   * @param $event - Event from the double click event.
   */
  changeViewEvent($event) {
    this._mongLogTableService.setLastScreen('active');
    const tab = TabService.getInstance().buildNewTab(
      MONLOG_DETAILS_CONTAINER,
      true,
      null,
      $event.data.id
    );
    TabService.getInstance().openTab(tab);
  }

  addEntryEvent($event) {
    if ($event.data.callType.toLowerCase() === A_AND_E_MAINTENANCE.toLowerCase() ||
        $event.data.callType.toLowerCase() === M_AND_R_MAINTENANCE.toLowerCase())
    {
      this.addEntryComponent().setForm(null, true, true, $event.data.id, true);
    }

    this.addEntryComponent().setForm(null, true, true, $event.data.id);
  }

  handleLazyLoad(req, $event, filters) {
    let selectedBusinessUnits = [];
    const businessUnitFilter = this.businessUnitFilter();
    if (businessUnitFilter?.selectedBusinessUnits) {
      // Handle further refreshes & changes to business unit filter component
      selectedBusinessUnits = businessUnitFilter.selectedBusinessUnits;
    } else {
      // Handle initial page load (before the business unit filter subcomponent exists)
      selectedBusinessUnits = this._authApi
        .getUserBusinessUnits()
        .map((bu) => bu.id);
    }
    filters['businessUnits'] = selectedBusinessUnits;

    const query = this._monLogBuilder.buildActiveMonLogQuery(filters);
    this.queryNetwork(req, $event, query);
  }

  handleAdditionalFilter($event) {
    const showAE = $event.value.some((i) => i === SHOW_AE);
    const showMR = $event.value.some((i) => i === SHOW_MR);
    this.hideAE = !showAE;
    this.hideMR = !showMR;
    this._mongLogTableService.setAdditionalFilterOptions({
      hideAE: this.hideAE,
      hideMR: this.hideMR,
    });
    this.lazyLoad(this.lastLazyEvent);
  }

  handleBusinessUnitChange($event) {
    this.lazyLoad(this.lastLazyEvent);
  }

  private queryNetwork(req, $event, query) {
    if (!this._isRefreshing) {
      this._isRefreshing = true;

      const sort = {};
      if ($event.lazy && $event.lazy.sortField) {
        // sorting
        if ($event.lazy.sortField === 'statusName') {
          sort['logStatus'] =
            $event.lazy.sortOrder && $event.lazy.sortOrder === 1
              ? 'ASC'
              : 'DESC';
        } else {
          sort[$event.lazy.sortField] =
            $event.lazy.sortOrder && $event.lazy.sortOrder === 1
              ? 'ASC'
              : 'DESC';
        }
      } else {
        sort['createdAt'] = 'DESC';
      }

      const typesToHideArray = [];
      if (this.hideAE) {
        typesToHideArray.push(A_AND_E_MAINTENANCE);
      }
      if (this.hideMR) {
        typesToHideArray.push(M_AND_R_MAINTENANCE);
      }

      this._monLogsApi
        .getActiveMonLogs(
          req.pageSize,
          req.page + 1,
          query,
          sort,
          typesToHideArray
        )
        .pipe(take(1))
        .subscribe(
          ({ data }) => {
            if (!data.getActiveMonLogs) {
              this.elements = [];
              this.totalRecords = 0;
              this.loading = false;
              return;
            }

            this.allLogs = [
              ...data.getActiveMonLogs.items.map((i) =>
                this._monLogBuilder.buildActiveMonLogForTable(i)
              ),
            ];

            this.elements = this.allLogs;
            this.totalRecords = data.getActiveMonLogs.totalRecords;
            this.loading = false;
            this._cdRef.markForCheck();
            this._isRefreshing = false;
          },
          (error) => {
            console.error(error);
            this._logAndMessage.translateToErrorMessage({
              headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
              bodyKey: 'MONLOG.MESSAGES.ERROR.LOAD_LOGS',
            });
            this.allLogs = [];
            this.elements = this.allLogs;
            this.totalRecords = 0;
            this.loading = false;
            this._isRefreshing = false;
          }
        );
    }
  }

  /**
   * This handles in application unloads (route navigation).
   */
  canDeactivate(): Observable<boolean> | boolean {
    if (!TabService.getInstance().getActiveTab().header.startsWith('*')) {
      return true;
    } else {
      return new Observable((observer: Observer<boolean>) => {
        const unsaved$ = this._translateService.get(
          'COMMON.MESSAGES.CONFIRMATION.UNSAVED_CHANGES'
        );
        const yes$ = this._translateService.get('COMMON.LABEL.BUTTONS.LEAVE');
        const no$ = this._translateService.get('COMMON.LABEL.BUTTONS.CANCEL');
        const header$ = this._translateService.get(
          'COMMON.LABEL.HEADERS.LEAVE_PAGE'
        );
        forkJoin(unsaved$, yes$, no$, header$).subscribe((messages) => {
          this._confirmationService.confirm({
            header: messages[3],
            icon: 'fa fa-question-circle',
            acceptVisible: true,
            rejectVisible: true,
            acceptLabel: messages[1],
            rejectLabel: messages[2],
            message: messages[0],
            accept: () => {
              observer.next(true);
              observer.complete();
            },
            reject: () => {
              observer.next(false);
              observer.complete();
            },
          });
        });
      });
    }
  }

  refresh(setLoading = true): void {
    this.lazyLoad(
      { lazy: this._mongLogTableService.getLastLazyLoad() },
      setLoading
    );
  }

  setupActiveTabChangedSubscription(): void {
    this.activeTabChangedSub =
      TabService.getInstance().activeTabChanged.subscribe((tab) => {
        if (!isNullOrUndefined(tab) && tab.header === this.screenName) {
          this.refresh();
        }
      });
  }
}
