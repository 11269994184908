import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  signal,
  viewChild,
  inject,
} from '@angular/core';
import {
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { ConfirmationService, MenuItem, SelectItem } from 'primeng/api';
import { Editor } from 'primeng/editor';
import { forkJoin, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  COMPLETE_REVIEW,
  DELETE_LOG,
  EMAIL_LOG,
  PRINT,
  RESOLVE_LOG,
} from 'src/app/constants/action-constants';
import {
  ALL_MONLOG_SEARCH_CONTAINER,
  CONTACT_DETAIL_CONTAINER,
  MONLOG_DETAILS_CONTAINER,
  MONLOG_PRINT_CONTAINER,
} from 'src/app/constants/common.constants';
import {
  A_AND_E_LOG_CATEGORY,
  A_AND_E_MAINTENANCE,
  COMM_FAIL,
  GAS_RELEASE,
  M_AND_R_LOG_CATEGORY,
  M_AND_R_MAINTENANCE,
  SCADA_ALARMS,
  TOWER_LIGHTS,
  TOWER_LIGHTS_ML_TYPE,
  TRANSDUCER_ALARM,
} from 'src/app/constants/monlog-constants';
import { DetailsContainer } from 'src/app/core/containers/details-container';
import {
  clearFormArray,
  dynamicSort,
  filterDropdownResults,
  getDirtyValues,
  isNullOrEmptyArray,
  onlyUnique,
} from 'src/app/core/functions/common-functions';
import { TabService } from 'src/app/core/services/tab.service';
import { BreadCrumb } from 'src/app/model/common/bread-crumb';
import { DirtyStatus } from 'src/app/model/common/dirty-status';
import { FilterCriteria } from 'src/app/model/common/filterCriteria';
import { Contact } from 'src/app/model/contacts/contact';
import { Facility } from 'src/app/model/locations/facility';
import { MonLog } from 'src/app/model/monlogs/mon-log';
import { AdminBuilderService } from 'src/app/services/admin-builder.service';
import { AuthService } from 'src/app/services/auth.service';
import { BreadCrumbBuilderService } from 'src/app/services/breadcrumb-builder.service';
import { ContactApiService } from 'src/app/services/contact-api.service';
import { ContactBuilderService } from 'src/app/services/contact-builder.service';
import { ContactTableService } from 'src/app/services/contact-table.service';
import { DeviceService } from 'src/app/services/device.service';
import { FacilityApiService } from 'src/app/services/facility-api.service';
import { FacilityBuilderService } from 'src/app/services/facility-builder.service';
import { FacilityTableService } from 'src/app/services/facility-table.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LocationApiService } from 'src/app/services/location-api.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { MonlogsApiService } from 'src/app/services/monlogs-api.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { MonlogsBuilderService } from 'src/app/services/monlogs-builder.service';
import { MonLogsTableService } from 'src/app/services/monlogs-table.service';
import { v4 as uuid } from 'uuid';
import { getMonLogHeader } from '../functions/monlog-functions';
import { Schedule } from 'src/app/model/contacts/contact-list-scheduler';
import { isNullOrUndefined } from 'src/app/utils/utils';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent,
} from 'primeng/autocomplete';
import { MultiSelectChangeEvent } from 'primeng/multiselect';
import { FacilityContactsForMonLog } from 'src/app/model/monlogs/facility-contacts-for-mon-log';
import { MonLogContact } from 'src/app/model/monlogs/mon-log-call-contact';
import { MonLogsAddEntryComponent } from '../components/mon-logs-add-entry.component';
import { ControlRoomEventReviewEntry } from 'src/app/model/monlogs/control-room-event-review-entry';
import { EventReviewDialogComponent } from '../components/event-review-dialog/event-review-dialog.component';
import { CLAIMS } from 'src/app/constants/auth-constants';

@Component({
  selector: 'app-monlogs-detail-container',
  templateUrl: './monlogs-detail-container.component.html',
  styleUrls: ['./monlogs-detail-container.component.scss'],
  standalone: false,
})
export class MonlogsDetailContainerComponent
  extends DetailsContainer
  implements OnInit, OnDestroy
{
  protected _translateService: TranslateService;
  private _breadCrumbService = inject(BreadCrumbBuilderService);
  protected _deviceService: DeviceService;
  private _fb = inject(UntypedFormBuilder);
  private _cdRef = inject(ChangeDetectorRef);
  protected _logAndMessage = inject(LogAndMessageService);
  protected _confirmationService: ConfirmationService;
  private _monLogApi = inject(MonlogsApiService);
  private _authApi = inject(AuthApiService);
  private _monLogBuilder = inject(MonlogsBuilderService);
  protected _locationApi: LocationApiService;
  protected _contactApi: ContactApiService;
  protected _contactTable = inject(ContactTableService);
  private _contactBuilder = inject(ContactBuilderService);
  protected _facilityApi: FacilityApiService;
  private _activeRoute = inject(ActivatedRoute);
  protected _facilityBuilder = inject(FacilityBuilderService);
  protected _facilityTableService: FacilityTableService;
  private _auth = inject(AuthService);
  private _adminBuilder = inject(AdminBuilderService);
  private _monLogTableService = inject(MonLogsTableService);
  private _loader = inject(LoadingService);

  readonly editor = viewChild(Editor);
  readonly addEntryComponent = viewChild(MonLogsAddEntryComponent);
  readonly eventReviewDialog = viewChild(EventReviewDialogComponent);

  tabs: any[];
  monLog: MonLog;
  monLogBreadCrumb: BreadCrumb;
  types: SelectItem[];
  showGasReleaseType = false;
  showAEWorkTypes = false;
  showMRWorkTypes = false;
  showSeverity = false;
  showNotam = false;
  isEditting = false;
  gasReleaseTypes: SelectItem[];
  commFailSeverities: SelectItem[];
  aeWorkTypes: SelectItem[];
  mrWorkTypes: SelectItem[];
  states: any[];
  filteredStates: any[];
  counties: any[];
  filteredCounties: any[];
  townships: any[];
  filteredTownships: any[];

  facilities: Facility[];
  facilityTypes: any[];
  filteredFacilityTypes: any[];
  facilityNames: any[];
  filteredFacilityNames: any[];
  facilityIds: any[];
  filteredFacilityIds: any[];
  filteredBusinessUnits: any[];
  facilitiesLoading: boolean = true;

  contacts: any[];
  filteredContacts: any[];
  showEntryDialog = false;

  addingAction = false;
  saving = false;
  forwardOptions: MenuItem[];
  primaryContact: any;
  primaryContacts: MenuItem[];

  actions: SelectItem[] = [];

  quillModules: any;
  sub: Subscription;

  validationMessages: any;
  validationParams: any;
  canCreate = false;
  canEdit = false;
  canDelete = false;
  isOneCallOnly = false;
  canViewEventReview = signal(false);
  canCreateEventReviewEntries = signal(false);

  deleteMsg: string;
  deleteEntryMsg: string;
  deleteEntryHeader: string;
  okBtnLabel: string;
  cancelBtnLabel: string;
  deleteHeader: string;
  isAlarm = false;

  displayEmailDialog = false;
  emailDialogHeader: string;
  selectedAction: any = null;
  valueSub: Subscription;
  showGasReleasePopupMessage = false;
  showCalloutPopupMessage = false;
  tabIndex = 0;
  breadCrumbSub: Subscription;
  activeTabChangedSub: Subscription;
  contactListSchedules: Schedule[];

  constructor() {
    const _translateService = inject(TranslateService);
    const _deviceService = inject(DeviceService);
    const _confirmationService = inject(ConfirmationService);
    const _locationApi = inject(LocationApiService);
    const _contactApi = inject(ContactApiService);
    const _facilityApi = inject(FacilityApiService);
    const _facilityTableService = inject(FacilityTableService);

    super(
      _translateService,
      _deviceService,
      _confirmationService,
      _locationApi,
      _contactApi,
      _facilityTableService,
      _facilityApi
    );
    this._translateService = _translateService;
    this._deviceService = _deviceService;
    this._confirmationService = _confirmationService;
    this._locationApi = _locationApi;
    this._contactApi = _contactApi;
    this._facilityApi = _facilityApi;
    this._facilityTableService = _facilityTableService;
  }

  ngOnInit() {
    this.setupTabClosingSubscription();
    this.setupActiveTabChangedSubscription();
    this.setupBreadCrumbSub();
    this.autoSelect = true;

    if (this._loader.isLoaded()) {
      this.initializeMonLog();
    } else {
      this._loader.loadingFinishedEvent.pipe(take(1)).subscribe(() => {
        this.initializeMonLog();
      });
    }
    this.loadFacilities();
    this.loadContacts();
  }

  refresh() {
    this.loading = true;
    this.ngOnInit(); //reloading page
  }

  setupBreadCrumbSub(): void {
    this.breadCrumbSub =
      this._breadCrumbService.breadCrumbSelectedEvent.subscribe(
        (breadCrumb) => {
          this._breadCrumbService.removeBreadCrumb(this.monLogBreadCrumb);
          const tab = TabService.getInstance().buildNewTab(
            ALL_MONLOG_SEARCH_CONTAINER,
            true
          );
          TabService.getInstance().setMobileTab(tab);
        }
      );
  }

  initializeMonLog() {
    const id = this.id ? this.id : this._activeRoute.snapshot.params['id'];
    const deleteMsg$ = this._translateService.get(
      'MONLOG.MESSAGES.CONFIRMATION.DELETE_MONLOG'
    );
    const deleteEntryMsg$ = this._translateService.get(
      'COMMON.MESSAGES.CONFIRMATION.DELETE_ENTRY'
    );
    const ok$ = this._translateService.get('COMMON.LABEL.BUTTONS.YES');
    const cancel$ = this._translateService.get('COMMON.LABEL.BUTTONS.NO');
    const header$ = this._translateService.get(
      'MONLOG.MESSAGES.HEADERS.DELETE_MONLOG'
    );
    const deleteEntryHeader$ = this._translateService.get(
      'COMMON.MESSAGES.HEADERS.DELETE_ENTRY'
    );
    forkJoin([
      deleteMsg$,
      ok$,
      cancel$,
      header$,
      deleteEntryMsg$,
      deleteEntryHeader$,
    ]).subscribe((messages) => {
      this.deleteMsg = messages[0];
      this.okBtnLabel = messages[1];
      this.cancelBtnLabel = messages[2];
      this.deleteHeader = messages[3];
      this.deleteEntryMsg = messages[4];
      this.deleteEntryHeader = messages[5];
    });

    const validations = this._monLogBuilder.buildMonLogValidationMessages();
    this.validationMessages = validations.messages;
    this.validationParams = validations.params;

    // If the id is falsy, then we need to setup a new mon log form
    // so grab the empty log from selected
    if (!id) {
      this.monLog = this._monLogTableService.getSelected();
      if (!this.monLog) {
        this._logAndMessage.errorLogOnly('Mon log was null.');

        if (this._deviceService.isMobile()) {
          const tab = TabService.getInstance().buildNewTab(
            ALL_MONLOG_SEARCH_CONTAINER,
            true
          );
          TabService.getInstance().setMobileTab(tab);
        } else {
          const index = TabService.getInstance().getActiveIndex();
          TabService.getInstance().closeTab(index);
        }
      }
      this.initialize();
    } else {
      // Otherwise, grab the log from the db via the id
      this._monLogApi
        .getMonitoringLogWithAlarms(id)
        .pipe(take(1))
        .subscribe({
          next: ({ data }) => {
            const clone = Object.assign({}, data);
            if (clone.getMonitoringLogWithAlarms) {
              this.monLog = this._monLogBuilder.buildMonLog(
                clone.getMonitoringLogWithAlarms
              );
              this.initialize();
            } else {
              // nothing found, go back to search.
              this._logAndMessage.errorLogOnly(
                'Could not find mon log with ID: ' + id
              );

              if (this._deviceService.isMobile()) {
                const tab = TabService.getInstance().buildNewTab(
                  ALL_MONLOG_SEARCH_CONTAINER,
                  true
                );
                TabService.getInstance().setMobileTab(tab);
              } else {
                const index = TabService.getInstance().getActiveIndex();
                TabService.getInstance().closeTab(index);
              }
            }
          },
          error: (error) => {
            // nothing found, go back to search or previous tab
            this._logAndMessage.errorLogOnly(
              'Error finding mon log with ID: ' + id
            );
            if (this._monLogTableService.getLastScreen() === 'all') {
              if (this._deviceService.isMobile()) {
                const tab = TabService.getInstance().buildNewTab(
                  ALL_MONLOG_SEARCH_CONTAINER,
                  true
                );
                TabService.getInstance().setMobileTab(tab);
              } else {
                const index = TabService.getInstance().getActiveIndex();
                TabService.getInstance().closeTab(index);
              }
            } else {
              const index = TabService.getInstance().getActiveIndex();
              TabService.getInstance().closeTab(index);
            }
          },
          complete: () => {
            this.doCheckComplete = false;
          },
        });
    }
  }

  initialize() {
    if (
      isNullOrUndefined(this.monLog) ||
      (!isNullOrUndefined(this.monLog) && this.monLog.id === null)
    ) {
      this.isEditting = true;
    } else {
      if (this.monLog.MonLogTypes.some((type) => type.label === 'Comm Fail')) {
        this.showSeverity = true;
      }

      if (
        this.monLog.MonLogTypes.some((type) => type.label === 'Gas Release')
      ) {
        this.showGasReleaseType = true;
      }

      if (
        this.monLog.MonLogTypes.some((type) => type.label === 'Tower Lights')
      ) {
        this.showNotam = true;
      }
    }

    if (!this._deviceService.isMobile()) {
      this.screenName = getMonLogHeader(this.monLog);
      TabService.getInstance().updateActiveTabLabel(
        getMonLogHeader(this.monLog)
      );
    }

    this.buildForm();
    this.updateForm();
    this.canCreate = this._authApi.doesUserHaveClaim(CLAIMS.MONITORING_CENTER.MON_LOGS.CREATE_MON_LOGS);
    this.canEdit = this._authApi.doesUserHaveClaim(CLAIMS.MONITORING_CENTER.MON_LOGS.EDIT_MON_LOGS);
    this.canDelete = this._authApi.doesUserHaveClaim(CLAIMS.MONITORING_CENTER.MON_LOGS.DELETE_MON_LOGS);
    this.canViewEventReview.set(
      this._authApi.doesUserHaveClaim(CLAIMS.CONTROL_ROOM.CONTROL_ROOM_EVENTS.VIEW_CONTROL_ROOM_EVENT_REVIEW)
    );
    this.canCreateEventReviewEntries.set(
      this._authApi.doesUserHaveClaim(CLAIMS.CONTROL_ROOM.CONTROL_ROOM_EVENTS.ENTRIES.CREATE_CONTROL_ROOM_EVENT_REVIEW_ENTRIES)
    );
    this.isOneCallOnly =
      this._authApi.doesUserHaveAtLeastOneClaimFromList([
        CLAIMS.MONITORING_CENTER.MON_LOGS.EMERGENCY_LOCATE_LOGS.CREATE_EMERGENCY_LOCATE_LOGS,
        CLAIMS.MONITORING_CENTER.MON_LOGS.EMERGENCY_LOCATE_LOGS.EDIT_EMERGENCY_LOCATE_LOGS,
      ]) && !(this.canCreate || this.canEdit);

    if (
      this.canEdit === false &&
      this.isOneCallOnly &&
      this.monLog.MonLogTypes &&
      this.monLog.MonLogTypes.some(
        (t) => t.label.toLowerCase() === 'Emergency Locate'.toLowerCase()
      )
    ) {
      this.canEdit = true;
    }

    if (
      this.canCreate === false &&
      this.isOneCallOnly &&
      this.monLog.MonLogTypes &&
      this.monLog.MonLogTypes.some(
        (t) => t.label.toLowerCase() === 'Emergency Locate'.toLowerCase()
      )
    ) {
      this.canCreate = true;
    }

    this.loadDropDownData();

    if (
      this._deviceService.isMobile() &&
      (!this._monLogTableService.getLastScreen() ||
        this._monLogTableService.getLastScreen() === 'all')
    ) {
      this._translateService
        .get('MONLOG.SCREEN.ALL_LOGS')
        .subscribe((label) => {
          this._breadCrumbService.resetAndAddBreadCrumb(
            new BreadCrumb(label, null, true)
          );
          if (this.monLog) {
            this.monLogBreadCrumb = new BreadCrumb(
              this.monLog.logId,
              null,
              false
            );
          }
          this._breadCrumbService.addBreadCrumb(this.monLogBreadCrumb);
        });
    }

    this.loadTypes();

    this._monLogApi
      .loadAvailableGasReleaseTypeGrades()
      .pipe(take(1))
      .subscribe((data) => {
        const clone = { ...data };
        const types = clone.data.getAvailableGasReleaseTypeGrades;
        this.gasReleaseTypes = [
          { label: 'Choose', value: null },
          ...types.map((t) => ({ label: t.name, value: t.id })),
        ];
      });

    this._monLogApi
      .loadAvailableCommFailSeverities()
      .pipe(take(1))
      .subscribe((data) => {
        const clone = { ...data };
        const types = clone.data.getAvailableCommFailSeverities;
        this.commFailSeverities = [
          { label: 'Choose', value: null },
          ...types.map((t) => ({ label: t.name, value: t.id })),
        ];
      });

    this._monLogApi
      .loadAvailableMonLogWorkTypes()
      .pipe(take(1))
      .subscribe((data) => {
        const clone = { ...data };
        const types = clone.data.getMonLogWorkTypes.items;
        this.aeWorkTypes = [
          ...types
            .filter((t) => t.category === 'AE')
            .map((t) => ({ label: t.name, value: t.id })),
        ].sort(dynamicSort('label', 1));

        this.mrWorkTypes = [
          ...types
            .filter((t) => t.category === 'MR')
            .map((t) => ({ label: t.name, value: t.id })),
        ].sort(dynamicSort('label', 1));
      });

    this.updateActionsList();
    this.loading = false;
  }

  loadFacilities() {
    this.facilitiesLoading = true;
    this._facilityApi.getFacilities().subscribe((data) => {
      const facilitiesClone = [...data];
      this.facilities = facilitiesClone.map((f: any) =>
        this._facilityBuilder.buildFacility(f, f.Locations)
      );
      this.facilitiesLoading = false;
    });
  }

  loadContacts() {
    this._contactApi
      .findContacts()
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = [...data.getContacts];
        const arr = clone.map((c) => this._contactBuilder.buildContact(c));
        this.contacts = arr;
      });
  }

  facilityTypeSearch($event: AutoCompleteCompleteEvent) {
    if (this.form) {
      const facilityGroup = this.form.get('Facility') as UntypedFormGroup;
      const state = this.form.controls['state'].value;
      const countyContainer = this.form.controls['county'].value;
      const townshipContainer = this.form.controls['township'].value;
      const businessUnit = facilityGroup.controls['businessUnit'].value;

      const queryArray: FilterCriteria[] = [];
      if (state) {
        queryArray.push(new FilterCriteria('state', state, 'include'));
      }
      if (countyContainer && countyContainer.county) {
        queryArray.push(
          new FilterCriteria('county', countyContainer.county, 'include')
        );
      }
      if (townshipContainer && townshipContainer.township) {
        queryArray.push(
          new FilterCriteria('township', townshipContainer.township, 'include')
        );
      }
      if (businessUnit) {
        queryArray.push(
          new FilterCriteria('businessUnit.id', businessUnit.id, 'startsWith')
        );
      }
      if ($event.query !== '') {
        queryArray.push(
          new FilterCriteria('facilityType', $event.query, 'startsWith')
        );
      }

      if (this.facilities) {
        this.filteredFacilityTypes = filterDropdownResults(
          this.facilities,
          queryArray
        )
          .map((ft: any) => ft.facilityType)
          .filter(onlyUnique);
        this.filteredFacilityTypes.sort();
      }
    }
  }

  facilityNameSearch($event: AutoCompleteCompleteEvent) {
    const facilityGroup = this.form.get('Facility') as UntypedFormGroup;
    const state = this.form.controls['state'].value;
    const countyContainer = this.form.controls['county'].value;
    const townshipContainer = this.form.controls['township'].value;
    const facilityType = facilityGroup.controls['facilityType'].value;
    const businessUnit = facilityGroup.controls['businessUnit'].value;

    const queryArray: FilterCriteria[] = [];
    if (state) {
      queryArray.push(new FilterCriteria('state', state, 'include'));
    }
    if (countyContainer && countyContainer.county) {
      queryArray.push(
        new FilterCriteria('county', countyContainer.county, 'include')
      );
    }
    if (townshipContainer && townshipContainer.township) {
      queryArray.push(
        new FilterCriteria('township', townshipContainer.township, 'include')
      );
    }
    if (facilityType) {
      queryArray.push(
        new FilterCriteria('facilityType', facilityType, 'startsWith')
      );
    }
    if (businessUnit) {
      queryArray.push(
        new FilterCriteria('businessUnit.id', businessUnit.id, 'startsWith')
      );
    }
    if ($event.query !== '') {
      queryArray.push(
        new FilterCriteria('facilityName', $event.query, 'startsWith')
      );
    }

    let facilityNames = filterDropdownResults(this.facilities, queryArray);
    facilityNames.sort(dynamicSort('facilityName', 1));
    this.filteredFacilityNames = facilityNames;
    if (this.filteredFacilityNames.length === 1) {
      facilityGroup.patchValue({ facilityName: this.filteredFacilityNames[0] });
      this.facilityNameSelected(this.filteredFacilityNames[0]);
    }
  }

  facilityNameSelected($event: AutoCompleteSelectEvent) {
    const eventValue: Facility = $event.value ? $event.value : $event;
    if (eventValue?.id) {
      this._facilityApi
        .getFacility(eventValue.id)
        .pipe(take(1))
        .subscribe(
          ({ data }) => {
            let thisFacility = data.getFacility;
            if (
              thisFacility.Locations.length > 0 &&
              this.monLog.logCategory !== A_AND_E_LOG_CATEGORY &&
              this.monLog.logCategory !== M_AND_R_LOG_CATEGORY
            ) {
              let location = null;
              if (
                !isNullOrUndefined(this.form) &&
                !isNullOrUndefined(this.form.controls['township']) &&
                !isNullOrUndefined(this.form.controls['township'].value) &&
                !isNullOrUndefined(
                  this.form.controls['township'].value.township
                )
              ) {
                const existingLoc = this.form.controls['township'].value;
                location = thisFacility.Locations.find(
                  (loc) => loc.township === existingLoc.township
                );
              } else {
                location = thisFacility.Locations[0];
              }

              this.form.patchValue({
                locationId: location.id,
                state: location.state,
                county: { county: location.county, state: location.state },
                township: { township: location.township },
              });
              this.form.controls['state'].markAsDirty();
              this.form.controls['county'].markAsDirty();
              this.form.controls['township'].markAsDirty();
            }
            const facilityFormGroup = this.form.get(
              'Facility'
            ) as UntypedFormGroup;
            facilityFormGroup.patchValue({
              facilityUid: eventValue,
              facilityType: thisFacility.FacilityType?.name,
              aor: thisFacility.Aor?.name,
              facilityId: thisFacility.id,
            });
            facilityFormGroup.controls['facilityUid'].markAsDirty();
            facilityFormGroup.controls['facilityType'].markAsDirty();
            facilityFormGroup.controls['aor'].markAsDirty();
            facilityFormGroup.controls['company'].markAsDirty();
            facilityFormGroup.controls['facilityId'].markAsDirty();

            // get the first business unit and set
            const businessUnit = thisFacility.businessUnit;
            if (businessUnit != null) {
              facilityFormGroup.patchValue({
                businessUnit: businessUnit,
              });
              facilityFormGroup.controls['businessUnit'].markAsDirty();
            }

            this.handleFacilityContacts(eventValue.id);
          },
          (error) => {
            console.error(error);
            this._logAndMessage.errorMessageOnly(
              'Facility not found',
              'Selected Facility was not found in the database. Re-open the dropdown and try again.'
            );
            const group = this.form.get('Facility') as UntypedFormGroup;
            group.patchValue({
              facilityName: null,
            });
          }
        );
    }
  }

  facilityIdSearch($event: AutoCompleteCompleteEvent) {
    const facilityGroup = this.form.get('Facility') as UntypedFormGroup;
    const state = this.form.controls['state'].value;
    const countyContainer = this.form.controls['county'].value;
    const townshipContainer = this.form.controls['township'].value;
    const facilityType = facilityGroup.controls['facilityType'].value;
    const facilityNameContainer = facilityGroup.controls['facilityName'].value;
    const businessUnit = facilityGroup.controls['businessUnit'].value;

    const queryArray: FilterCriteria[] = [];
    if (state) {
      queryArray.push(new FilterCriteria('state', state, 'include'));
    }
    if (countyContainer && countyContainer.county) {
      queryArray.push(
        new FilterCriteria('county', countyContainer.county, 'include')
      );
    }

    if (townshipContainer && townshipContainer.township) {
      queryArray.push(
        new FilterCriteria('township', townshipContainer.township, 'include')
      );
    }

    if (facilityType) {
      queryArray.push(
        new FilterCriteria('facilityType', facilityType, 'startsWith')
      );
    }

    if (facilityNameContainer && facilityNameContainer.facilityName) {
      queryArray.push(
        new FilterCriteria(
          'facilityName',
          facilityNameContainer.facilityName,
          'startsWith'
        )
      );
    }

    if (businessUnit) {
      queryArray.push(
        new FilterCriteria('businessUnit.id', businessUnit.id, 'startsWith')
      );
    }

    if ($event.query !== '') {
      queryArray.push(
        new FilterCriteria('facilityUid', $event.query, 'startsWith')
      );
    }

    this.filteredFacilityIds = filterDropdownResults(
      this.facilities,
      queryArray
    );
    this.filteredFacilityIds.sort(dynamicSort('facilityUid', 1));
    if (this.filteredFacilityIds.length === 1) {
      facilityGroup.patchValue({ facilityUid: this.filteredFacilityIds[0] });
      this.facilityIdSelected(this.filteredFacilityIds[0]);
    }
  }

  facilityIdSelected($event: AutoCompleteSelectEvent) {
    const eventValue: Facility = $event.value ? $event.value : $event;
    this._facilityApi
      .getFacility(eventValue.id)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          let thisFacility = data.getFacility;
          if (
            thisFacility.Locations.length > 0 &&
            this.monLog.logCategory !== A_AND_E_LOG_CATEGORY &&
            this.monLog.logCategory !== M_AND_R_LOG_CATEGORY
          ) {
            let location = null;
            if (
              !isNullOrUndefined(this.form) &&
              !isNullOrUndefined(this.form.controls['township']) &&
              !isNullOrUndefined(this.form.controls['township'].value) &&
              !isNullOrUndefined(this.form.controls['township'].value.township)
            ) {
              const existingLoc = this.form.controls['township'].value;
              location = thisFacility.Locations.find(
                (loc) => loc.township === existingLoc.township
              );
            } else {
              location = thisFacility.Locations[0];
            }

            this.form.patchValue({
              locationId: location.id,
              state: location.state,
              county: { county: location.county, state: location.state },
              township: { township: location.township },
            });
            this.form.controls['state'].markAsDirty();
            this.form.controls['county'].markAsDirty();
            this.form.controls['township'].markAsDirty();
          }
          const facilityFormGroup = this.form.get(
            'Facility'
          ) as UntypedFormGroup;
          facilityFormGroup.patchValue({
            facilityName: eventValue,
            facilityType: thisFacility.FacilityType?.name,
            aor: thisFacility.Aor?.name,
            facilityId: thisFacility.id,
          });

          facilityFormGroup.controls['facilityName'].markAsDirty();
          facilityFormGroup.controls['facilityType'].markAsDirty();
          facilityFormGroup.controls['aor'].markAsDirty();
          facilityFormGroup.controls['company'].markAsDirty();
          facilityFormGroup.controls['facilityId'].markAsDirty();

          // get the first business unit and set
          const businessUnit = thisFacility.businessUnit;
          if (businessUnit != null) {
            facilityFormGroup.patchValue({
              businessUnit: businessUnit,
            });
            facilityFormGroup.controls['businessUnit'].markAsDirty();
          }

          this.handleFacilityContacts(eventValue.id);
        },
        (error) => {
          console.error(
            'Facility that was selected was not found in database!'
          );
          console.error(error);
          this._logAndMessage.errorMessageOnly(
            'Facility not found',
            'Selected Facility was not found in the database. Re-open the dropdown and try again.'
          );
          const group = this.form.get('Facility') as UntypedFormGroup;
          group.patchValue({
            facilityUid: null,
          });
        }
      );
  }

  businessUnitSearch($event: AutoCompleteCompleteEvent) {
    const facilityGroup = this.form.get('Facility') as UntypedFormGroup;
    const state = this.form.controls['state'].value;
    const countyContainer = this.form.controls['county'].value;
    const townshipContainer = this.form.controls['township'].value;
    const facilityType = facilityGroup.controls['facilityType'].value;
    const facilityNameContainer = facilityGroup.controls['facilityName'].value;

    const queryArray: FilterCriteria[] = [];
    if (state) {
      queryArray.push(new FilterCriteria('state', state, 'include'));
    }
    if (countyContainer && countyContainer.county) {
      queryArray.push(
        new FilterCriteria('county', countyContainer.county, 'include')
      );
    }
    if (townshipContainer && townshipContainer.township) {
      queryArray.push(
        new FilterCriteria('township', townshipContainer.township, 'include')
      );
    }

    if (facilityType) {
      queryArray.push(
        new FilterCriteria('facilityType', facilityType, 'startsWith')
      );
    }

    if (facilityNameContainer && facilityNameContainer.facilityName) {
      queryArray.push(
        new FilterCriteria(
          'facilityName',
          facilityNameContainer.facilityName,
          'startsWith'
        )
      );
    }

    if ($event.query !== '') {
      queryArray.push(
        new FilterCriteria('businessUnits', $event.query, 'startsWith')
      );
    }

    var filteredFacilities = filterDropdownResults(this.facilities, queryArray);
    this.filteredBusinessUnits = filteredFacilities
      .map((f) => f.businessUnit)
      .reduce((acc, val) => acc.concat(val), [])
      .filter(
        (val, index, self) => self.map((e) => e.id).indexOf(val.id) == index
      );

    // Ensure that we only show business units that the user has access to
    // even if one of their facilities has a BU that they don't have
    const userBusinessUnitNames = this._authApi
      .getUserBusinessUnits()
      .map((userBu) => userBu.name);
    this.filteredBusinessUnits = this.filteredBusinessUnits.filter((filterBu) =>
      userBusinessUnitNames.includes(filterBu.name)
    );

    this.filteredBusinessUnits.sort();
    if (this.filteredBusinessUnits.length == 1) {
      const facilityGroup = this.form.get('Facility') as UntypedFormGroup;
      facilityGroup.patchValue({ businessUnit: this.filteredBusinessUnits[0] });
    }
  }

  handleFacilityContacts(facId: string): void {
    this.loading = true;
    this._monLogApi.getFacilityContactsForMonLog(facId).subscribe({
      next: (facilityContacts: FacilityContactsForMonLog) => {
        // Track index so we can add to the appropriate slots based on what is returned
        let idx = 0;
        if (facilityContacts.PrimaryTechnician) {
          this.addContactToForm(idx, facilityContacts.PrimaryTechnician);
          idx++;
        }
        if (facilityContacts.FirstOnCall) {
          this.addContactToForm(idx, facilityContacts.FirstOnCall);
        }

        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this._logAndMessage.translateToErrorAlert({
          headerKey: 'COMMON.MESSAGES.HEADERS.RETRIEVE_ERROR',
          bodyKey: 'MONLOG.MESSAGES.ERROR.GET_CONTACTS_FOR_MON_LOG',
        });
      },
    });
  }

  setTimeBegan() {
    this.form.patchValue({
      timeBegan: new Date(),
    });

    this.form.controls['timeBegan'].markAsDirty();
    this.saveMonitoringLog();
  }

  setTimeOnSite() {
    this.form.patchValue({
      timeOnSite: new Date(),
    });
    this.form.controls['timeOnSite'].markAsDirty();
    this.saveMonitoringLog();
  }

  clearScreen() {
    this.buildForm();
  }

  setEditting() {
    this.isEditting = true;
    this.form.enable();
    const group = this.form.get('Facility') as UntypedFormGroup;
    group.controls['aor'].disable();
    group.controls['company'].disable();
    this.addNewContactRow();
  }

  saveMonitoringLog() {
    this.saving = true;
    this._cdRef.detectChanges();
    this.isEditting = false;
    const dv = getDirtyValues(this.form);

    // Need to gather details for the graphql offline translation.
    if (dv['MonLogCallouts']) {
      dv['MonLogCallouts'].forEach((co) => {
        const array = this.monLog.MonLogCallouts.filter(
          (callout) => co.id === callout.id
        );

        if (array.length > 0) {
          const _callout = array[0];
          _callout.startDt = co.startDt;
          _callout.endDt = co.endDt;
          _callout.dirtyStatus = DirtyStatus.UPDATED;
        }
      });
    }

    let selectedGasGrade = null;
    if (dv && dv['gasReleaseType']) {
      const id = dv['gasReleaseType'];
      selectedGasGrade = {
        id,
        name: this.gasReleaseTypes.filter((g) => g.value === id)[0].label,
      };
    }

    let selectedCommFail = null;
    if (dv && dv['commFailSeverity']) {
      const id = dv['commFailSeverity'];
      selectedCommFail = {
        id,
        name: this.commFailSeverities.filter((g) => g.value === id)[0].label,
      };
    }

    if (dv && dv['typeOfCalls']) {
      const types = dv['typeOfCalls'];
      const availableTypes = this.types;
      const newTypes = [];
      types.forEach((t, i, arr) => {
        const array = availableTypes.filter((at) => at.value === t);
        if (array && array.length > 0) {
          newTypes.push({
            id: array[0].value,
            name: array[0].label,
          });
        }
      });
      dv['typeOfCalls'] = newTypes;
    }

    const updateStatement = this._monLogBuilder.buildUpdateStatement(
      this.monLog,
      dv
    );
    const contactDetails = {};

    if (dv && dv['MonLogContacts']) {
      dv['MonLogContacts'].forEach((c) => {
        const primary = c.primary;
        if (primary) {
          contactDetails[primary.primaryId] = {
            firstName: primary.primaryFirstName,
            lastName: primary.primaryLastName,
            businessMobile: primary.primaryBusinessMobile,
          };

          contactDetails[primary.supervisorId] = {
            firstName: primary.supervisorFirstName,
            lastName: primary.supervisorLastName,
            businessMobile: primary.supervisorBusinessMobile,
          };

          contactDetails[primary.managerId] = {
            firstName: primary.managerFirstName,
            lastName: primary.managerLastName,
            businessMobile: primary.managerBusinessMobile,
          };
        }
      });
    } else {
      this.monLog.MonLogContacts.forEach((c) => {
        contactDetails[c.primaryId] = {
          firstName: c.primaryFirstName,
          lastName: c.primaryLastName,
          businessMobile: c.primaryBusinessMobile,
        };

        contactDetails[c.supervisorId] = {
          firstName: c.supervisorFirstName,
          lastName: c.supervisorLastName,
          businessMobile: c.supervisorBusinessMobile,
        };

        contactDetails[c.managerId] = {
          firstName: c.managerFirstName,
          lastName: c.managerLastName,
          businessMobile: c.managerBusinessMobile,
        };
      });
    }

    if (dv && dv['internalOne']) {
      const one = dv['internalOne'];

      if (one) {
        contactDetails[one.id] = {
          firstName: one.firstName,
          lastName: one.lastName,
        };
      }
    } else {
      if (this.monLog.internalOne) {
        contactDetails[this.monLog.internalOne.id] = {
          firstName: this.monLog.internalOne.firstName,
          lastName: this.monLog.internalOne.lastName,
        };
      }
    }

    if (dv && dv['internalTwo']) {
      const two = dv['internalTwo'];
      if (two) {
        contactDetails[two.id] = {
          firstName: two.firstName,
          lastName: two.lastName,
        };
      }
    } else {
      if (this.monLog.internalTwo) {
        contactDetails[this.monLog.internalTwo.id] = {
          firstName: this.monLog.internalTwo.firstName,
          lastName: this.monLog.internalTwo.lastName,
        };
      }
    }

    if (this.monLog.id) {
      this.updateMonitoringLog(updateStatement);
    } else {
      this.monLog.logId = null;
      this.createMonitorLog(updateStatement);
    }
  }

  updateMonitoringLog(updateStatement) {
    if (!this.canEdit) {
      this._logAndMessage.error(
        'Cannot update monitoring log!',
        'Logged in user does not have appropriate claim.'
      );
      return;
    }
    this._monLogApi
      .updateMonLog(this.monLog, updateStatement)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          this.handleLogSave(Object.assign({}, data.updateMonLog));
        },
        (error) => {
          console.error(error);
          this.saving = false;
        },
        () => {
          this.saving = false;
        }
      );
  }

  createMonitorLog(updateStatement) {
    if (!this.canCreate) {
      this._logAndMessage.error(
        'Cannot create monitoring log!',
        'Logged in user does not have appropriate claim.'
      );
      return;
    }
    this._monLogApi
      .createMonLog(updateStatement)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          this.handleLogSave(Object.assign({}, data.createMonLog));
        },
        (error) => {
          console.error(error);
          this.saving = false;
        },
        () => {
          this.saving = false;
          const oldTab = TabService.getInstance().getActiveTab();
          const newTab = TabService.getInstance().buildNewTab(
            MONLOG_DETAILS_CONTAINER,
            true,
            null,
            this.monLog.id
          );
          TabService.getInstance().replaceTab(oldTab, newTab);
        }
      );
  }

  handleLogSave(data) {
    this._logAndMessage.translateToSuccessMessage({
      bodyKey: 'COMMON.MESSAGES.SUCCESS.SAVED_SUCCESSFULLY',
      headerKey: 'COMMON.MESSAGES.HEADERS.SUBMIT',
    });
    this.monLog = Object.assign({}, this._monLogBuilder.buildMonLog(data));
    this.saving = false;
    this.form.disable();
    this.form.markAsPristine();
    this.updateActionsList();
    this.checkAndUpdateLogCategory();

    const calloutArray = this.form.get('MonLogCallouts') as UntypedFormArray;
    const monLogCalloutIDs = this.monLog.MonLogCallouts.map(
      (callout) => callout.id
    ) as string[];
    const calloutFormIDs = calloutArray.value.forEach(
      (formControl) => formControl.id
    ) as string[];
    calloutArray.value.forEach((callout: any, index: number) => {
      if (!monLogCalloutIDs.includes(callout.id)) {
        (this.form.get('MonLogCallouts') as UntypedFormArray).removeAt(index);
      }
    });

    if (calloutFormIDs) {
      const idsToAdd = monLogCalloutIDs.filter(
        (id) => !calloutFormIDs.includes(id)
      );
      idsToAdd.forEach((id) => {
        const callout = this.monLog.MonLogCallouts.find((c) => c.id === id);
        if (callout) {
          (this.form.get('MonLogCallouts') as UntypedFormArray).push(
            this._fb.group({
              id: callout.id,
              firstName: callout.firstName,
              lastName: callout.lastName,
              startDt: callout.startDt,
              endDt: callout.endDt,
              contactId: callout.ContactId,
            })
          );
        }
      });
    }
    this._cdRef.markForCheck();

    const contactArray = this.form.get('MonLogContacts') as UntypedFormArray;
    if (contactArray) {
      const fg = contactArray.controls[
        contactArray.length - 1
      ] as UntypedFormGroup;
      // check the primary of the last row to see if anything was add.  If not then remove it.
      if (fg && !fg.controls['primary'].value) {
        contactArray.removeAt(contactArray.length - 1);
      }
    }
    this.changesEvent.emit({
      isDirty: false,
      index: this.index(),
      id: this.id,
    });

    // Toggle callout state on contacts
    const callouts = this.monLog.MonLogCallouts;
    contactArray.value.forEach((contactCollection: any, index: number) => {
      // Primary
      if (
        contactCollection.hasOwnProperty('primary') &&
        contactCollection.primary != null
      ) {
        const primaryID = contactCollection.primary.primaryId;
        const managerID = contactCollection.primary.managerId;
        const supervisorID = contactCollection.primary.supervisorId;
        contactCollection.primary.isPrimaryDispatched = callouts.some(
          (callout) => callout.ContactId === primaryID && !callout.endDt
        );
        contactCollection.primary.isManagerDispatched = callouts.some(
          (callout) => callout.ContactId === managerID && !callout.endDt
        );
        contactCollection.primary.isSupervisorDispatched = callouts.some(
          (callout) => callout.ContactId === supervisorID && !callout.endDt
        );
      }

      // Manager
      if (
        contactCollection.hasOwnProperty('manager') &&
        contactCollection.manager != null
      ) {
        const primaryID = contactCollection.manager.primaryId;
        const managerID = contactCollection.manager.managerId;
        const supervisorID = contactCollection.manager.supervisorId;
        contactCollection.manager.isPrimaryDispatched = callouts.some(
          (callout) => callout.ContactId === primaryID && !callout.endDt
        );
        contactCollection.manager.isManagerDispatched = callouts.some(
          (callout) => callout.ContactId === managerID && !callout.endDt
        );
        contactCollection.manager.isSupervisorDispatched = callouts.some(
          (callout) => callout.ContactId === supervisorID && !callout.endDt
        );
      }

      // Supervisor
      if (
        contactCollection.hasOwnProperty('supervisor') &&
        contactCollection.supervisor != null
      ) {
        const primaryID = contactCollection.supervisor.primaryId;
        const managerID = contactCollection.supervisor.managerId;
        const supervisorID = contactCollection.supervisor.supervisorId;
        contactCollection.supervisor.isPrimaryDispatched = callouts.some(
          (callout) => callout.ContactId === primaryID && !callout.endDt
        );
        contactCollection.supervisor.isManagerDispatched = callouts.some(
          (callout) => callout.ContactId === managerID && !callout.endDt
        );
        contactCollection.supervisor.isSupervisorDispatched = callouts.some(
          (callout) => callout.ContactId === supervisorID && !callout.endDt
        );
      }
    });

    this._cdRef.markForCheck();
    this.updateForm();
  }

  /**
   * Certain call types enable new fields to be populated.
   * @param $event
   */
  callTypeChange($event: MultiSelectChangeEvent) {
    const itemId = $event.itemValue?.value;
    const isAdd = $event.value.some((i) => i === itemId);
    const array = this.types.filter((type) => type.value === itemId);
    if (array.length > 0) {
      const type = array[0];
      if (type.label === GAS_RELEASE && type.value === itemId && isAdd) {
        this.showGasReleaseType = true;
        this.form.controls['gasReleaseType'].updateValueAndValidity();
      } else if (
        type.label === GAS_RELEASE &&
        type.value === itemId &&
        !isAdd
      ) {
        this.showGasReleaseType = false;
        this.form.controls['gasReleaseType'].patchValue(null);
        this.form.controls['gasReleaseType'].updateValueAndValidity();
      } else if (type.label === COMM_FAIL && type.value === itemId && isAdd) {
        this.showSeverity = true;
        this.form.controls['commFailSeverity'].setValidators([
          Validators.required,
        ]);
        this.form.controls['commFailSeverity'].updateValueAndValidity();
      } else if (type.label === COMM_FAIL && type.value === itemId && !isAdd) {
        this.showSeverity = false;
        this.form.controls['commFailSeverity'].patchValue(null);
        this.form.controls['commFailSeverity'].setValidators(null);
        this.form.controls['commFailSeverity'].updateValueAndValidity();
      } else if (
        type.label === TOWER_LIGHTS_ML_TYPE &&
        type.value === itemId &&
        isAdd
      ) {
        this.showNotam = true;
      } else if (
        type.label === TOWER_LIGHTS_ML_TYPE &&
        type.value === itemId &&
        !isAdd
      ) {
        this.showNotam = false;
        this.form.controls['notam'].patchValue(null);
      } else if (
        type.label === A_AND_E_MAINTENANCE &&
        type.value === itemId &&
        isAdd
      ) {
        this.showAEWorkTypes = true;
        this.form.controls['workTypes'].setValidators([Validators.required]);
        this.form.controls['workTypes'].updateValueAndValidity();
        this.monLog.logCategory = A_AND_E_LOG_CATEGORY;
      } else if (
        type.label === A_AND_E_MAINTENANCE &&
        type.value === itemId &&
        !isAdd
      ) {
        this.showAEWorkTypes = false;
        this.form.controls['workTypes'].patchValue(null);
        this.form.controls['workTypes'].setValidators(null);
        this.form.controls['workTypes'].updateValueAndValidity();
        this.monLog.logCategory = 'N';
      } else if (
        type.label === M_AND_R_MAINTENANCE &&
        type.value === itemId &&
        isAdd
      ) {
        this.showMRWorkTypes = true;
        this.form.controls['workTypes'].setValidators([Validators.required]);
        this.form.controls['workTypes'].updateValueAndValidity();
        this.monLog.logCategory = M_AND_R_LOG_CATEGORY;
      } else if (
        type.label === M_AND_R_MAINTENANCE &&
        type.value === itemId &&
        !isAdd
      ) {
        this.showMRWorkTypes = false;
        this.form.controls['workTypes'].patchValue(null);
        this.form.controls['workTypes'].setValidators(null);
        this.form.controls['workTypes'].updateValueAndValidity();
        this.monLog.logCategory = 'N';
      }
    }
  }

  buildForm() {
    this.form = this._fb.group({
      statusSummary: [null],
      logStatus: null,
      logCategory: null,
      reviewer: null,
      reviewCompletedDt: null,
      reviewRequestedDt: null,
      MonLogCallouts: this._fb.array([]),
      typeOfCalls: [null, Validators.required],
      customerOutage: [null, Validators.required],
      calloutRequired: [null, Validators.required],
      gasReleaseType: null,
      commFailSeverity: null,
      workTypes: null,
      notam: null,
      expireDt: null,
      oneCallNumber: null,
      falseAlarm: [null, Validators.required],
      followUpCall: null,
      timeBegan: [null, Validators.required],
      timeOnSite: null,
      ticketNumber: null,
      firstName: null,
      lastName: null,
      street: null,
      phone: null,
      state: null,
      county: null,
      township: null,
      city: null,
      internalOne: null,
      internalTwo: null,
      Facility: this._fb.group({
        facilityName: null,
        facilityType: null,
        facilityUid: null,
        aor: [{ value: null, disabled: true }],
        company: [{ value: null, disabled: true }],
        pipeline: null,
        facilityId: null,
        businessUnit: [null, Validators.required],
      }),
      MonLogContacts: this._fb.array([]),
    });

    this.valueSub = this.form.valueChanges.subscribe((change) => {
      if (this.form.dirty) {
        this.changesEvent.emit({
          isDirty: true,
          index: this.index(),
          id: this.id,
        });
      }
    });

    if (!this.monLog?.id) {
      // These fields have defaults set by the page and their values will not appear as dirty without this
      // we only want these to be set to dirty on a blank form though
      this.form.controls['timeBegan'].markAsDirty();
      this.form.controls['customerOutage'].markAsDirty();
      this.form.controls['calloutRequired'].markAsDirty();
      this.form.controls['falseAlarm'].markAsDirty();
    }
  }

  checkAndUpdateLogCategory() {
    // these need checked outside of a new log or existing log.
    if (
      this.monLog.MonLogTypes.some(
        (type) => type && type.label && type.label === A_AND_E_MAINTENANCE
      )
    ) {
      this.showAEWorkTypes = true;
      this.monLog.logCategory = A_AND_E_LOG_CATEGORY;
      this.form.controls['workTypes'].setValidators([Validators.required]);
      this.form.controls['workTypes'].updateValueAndValidity();
    }

    if (
      this.monLog.MonLogTypes.some(
        (type) => type && type.label && type.label === M_AND_R_MAINTENANCE
      )
    ) {
      this.showMRWorkTypes = true;
      this.monLog.logCategory = M_AND_R_LOG_CATEGORY;
      this.form.controls['workTypes'].setValidators([Validators.required]);
      this.form.controls['workTypes'].updateValueAndValidity();
    }

    if (
      this.monLog.MonLogTypes.some(
        (type) =>
          (type &&
            type.label &&
            type.label.toUpperCase() === SCADA_ALARMS.toUpperCase()) ||
          (type &&
            type.label &&
            type.label.toUpperCase() === TOWER_LIGHTS.toUpperCase()) ||
          (type &&
            type.label &&
            type.label.toUpperCase() === TRANSDUCER_ALARM.toUpperCase())
      )
    ) {
      this.isAlarm = true;
    }
  }

  updateForm() {
    this.checkAndUpdateLogCategory();
    this.form.patchValue({
      statusSummary: this.monLog.statusSummary,
      logStatus: this.monLog.logStatus,
      logCategory: this.monLog.logCategory,
      customerOutage: this.monLog.customerOutage,
      ticketNumber: this.monLog.ticketNumber,
      typeOfCalls: this.monLog.MonLogTypes.map((t) => t.value),
      workTypes: this.monLog.MonLogWorkTypes.map((t) => t.value),
      calloutRequired: this.monLog.calloutRequired,
      gasReleaseType: this.monLog.gasReleaseType
        ? this.monLog.gasReleaseType?.id
        : null,
      commFailSeverity: this.monLog.commFailSeverity
        ? this.monLog.commFailSeverity?.id
        : null,
      notam: this.monLog.notam,
      expireDt: this.monLog.expireDt,
      oneCallNumber: this.monLog.oneCallNumber,
      falseAlarm: this.monLog.falseAlarm,
      followUpCall: this.monLog.followUpCall,
      timeBegan: this.monLog.timeBegan,
      timeOnSite: this.monLog.timeOnSite,
      firstName: this.monLog.firstName,
      lastName: this.monLog.lastName,
      street: this.monLog.street,
      phone: this.monLog.phone,
      state: this.monLog.state,
      // county and township are objects and not straight fields. This is because of the selection/ auto update cases
      // these drop downs use.
      // However, for empty forms we want null instead of an object here
      county: this.monLog?.county
        ? {
            county: this.monLog.county,
            state: this.monLog.state,
          }
        : null,
      township: this.monLog.township
        ? { township: this.monLog.township }
        : null,
      city: this.monLog.city,
      internalOne: this.monLog?.internalOne,
      internalTwo: this.monLog?.internalTwo,
    });

    if (this.monLog.MonLogCallouts) {
      const calloutArray = this.form.get('MonLogCallouts') as UntypedFormArray;
      clearFormArray(calloutArray);
      this.monLog.MonLogCallouts.filter(
        (co) => co.dirtyStatus !== DirtyStatus.DELETED
      ).forEach((c) => {
        calloutArray.push(
          this._fb.group({
            id: c.id,
            firstName: c.firstName,
            lastName: c.lastName,
            startDt: c.startDt,
            endDt: c.endDt,
            contactId: c.ContactId,
          })
        );
      });
    }

    if (this.monLog.Facility) {
      this.form.get('Facility').patchValue({
        // facilityName and facilityUid are objects and not straight fields. This is because of the selection/ auto update cases
        // these drop downs use.
        // However, for empty forms we want null instead of an object here
        facilityName: this.monLog?.Facility?.facilityName
          ? {
              facilityName: this.monLog.Facility.facilityName,
            }
          : null,
        facilityUid: this.monLog?.Facility?.facilityUid
          ? {
              facilityUid: this.monLog.Facility.facilityUid,
            }
          : null,
        facilityType: this.monLog.Facility.facilityType,
        aor: this.monLog.Facility.aor,
        company: this.monLog.Facility.company,
        pipeline: this.monLog.pipeline,
        businessUnit: this.monLog.businessUnit,
      });
    }

    if (this.monLog.MonLogContacts) {
      const contactArray = this.form.get('MonLogContacts') as UntypedFormArray;
      clearFormArray(contactArray);
      this.monLog.MonLogContacts.forEach((c) => {
        contactArray.push(
          this._fb.group({
            id: c.id,
            primary: c,
            supervisor: c?.supervisorFullName
              ? {
                  value: c,
                  disabled: true,
                }
              : null,
            manager: c?.managerFullName
              ? {
                  value: c,
                  disabled: true,
                }
              : null,
          })
        );
      });
    }

    if (this.monLog.id === null) {
      this.addNewContactRow();
      const contactArray = this.form.get('MonLogContacts') as UntypedFormArray;
      contactArray.controls.forEach((c) => c.markAsDirty());
    }
    if (!this.isEditting) {
      this.form.disable();
    }

    if (this.isOneCallOnly) {
      // if the user is only in one call group then lock down the call type box.
      this.form.controls['typeOfCalls'].disable();
    }
  }

  private markFormGroupDirty(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsDirty();

      if (control.controls) {
        this.markFormGroupDirty(control);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.breadCrumbSub) {
      this.breadCrumbSub.unsubscribe();
    }

    if (this.sub) {
      this.sub.unsubscribe();
    }

    if (this.valueSub) {
      this.valueSub.unsubscribe();
    }

    if (this.tabClosingSub) {
      this.tabClosingSub.unsubscribe();
    }

    if (this.activeTabChangedSub) {
      this.activeTabChangedSub.unsubscribe();
    }
  }

  /**
   * Patches the contact list and adds an empty row for entry.
   * @param index - The index in the contacts array to add at
   * @param contactToAdd - The Contact object to add to the form
   */
  addContactToForm(index, contactToAdd: Contact) {
    this.addContactToContactRow(
      index,
      this._monLogBuilder.buildMonLogContactsFromContact(contactToAdd)
    );
    this.addNewContactRow();
  }

  /**
   * For the primary contact section when we select a contact we need to fill in the supervisor and manager fields.  The application
   * also needs to present a new empty row for another contact if needed.
   * @param i
   * @param $event
   */
  contactSelected(i, $event: AutoCompleteSelectEvent) {
    const eventValue = $event.value;
    let contactCount = 0;
    const contactArray = this.form.get('MonLogContacts') as UntypedFormArray;
    // validate that the contact seleted isn't already in the contact list.
    contactArray.controls.forEach((g) => {
      const existing = g['controls'].primary;
      const value = existing.value ? existing.value : null;
      if (value && value.primaryId === eventValue.primaryId) {
        contactCount++;
      }
    });

    this._contactApi
      .getContactById(eventValue.primaryId)
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = Object.assign({}, data);
        const contact = this._contactBuilder.buildContact(clone.getContact);
        const contactForRow =
          this._monLogBuilder.buildMonLogContactsFromContact(contact);

        if (contactCount <= 1) {
          this.addContactToContactRow(i, contactForRow);

          this.addNewContactRow();
        } else {
          this.addContactToContactRow(i, null);
          this._logAndMessage.translateToWarnMessage({
            headerKey: 'MONLOG.MESSAGES.HEADERS.DUPLICATE_CONTACT',
            bodyKey: 'MONLOG.MESSAGES.WARN.DUPLICATE_CONTACT_MSG',
          });
        }
      });
  }

  /**
   * Used to search for contacts by full name.  This is used for different types of contacts so a flag will determine the appropriate
   * mapping function to use for the data.
   * @param $event
   * @param isInternal
   */
  contactSearch($event: AutoCompleteCompleteEvent, isForInternalLogs = false) {
    let mappingFunction: Function =
      this._monLogBuilder.buildMonLogContactsFromContact;
    if (isForInternalLogs === true) {
      mappingFunction =
        this._monLogBuilder.buildMonLogInternalContactFromContact;
    }

    const query = {
      firstName: $event.query.toLowerCase(),
    };

    const sort = {
      firstName: 'ASC',
    };
    this._contactApi
      .queryForContactDropdown(999999, 1, query, sort)
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = Object.assign({}, data);
        this.filteredContacts = clone.queryForContactDropdown.items.map((c) =>
          mappingFunction(c)
        );
      });
  }

  showEntryForm(isAction = false) {
    this.showEntryDialog = true;
    this.addEntryComponent().setForm(this.monLog, isAction);
  }

  private checkResolutionForGasRelease(showMessage = true) {
    if (this.showGasReleaseType && !this.monLog.gasReleaseType) {
      if (showMessage) {
        this._logAndMessage.translateToErrorMessage({
          headerKey: 'MONLOG.MESSAGES.HEADERS.NO_GAS_RELEASE_TYPE_FOUND',
          bodyKey: 'MONLOG.MESSAGES.ERROR.NO_GAS_RELEASE_TYPE_FOUND_MSG',
        });
      }
      return false;
    }
    return true;
  }

  private addContactToContactRow(index: number, data: MonLogContact) {
    const contactArray = this.form.get('MonLogContacts') as UntypedFormArray;
    if (contactArray.at(index)) {
      contactArray.at(index).patchValue({
        primary: data,
        supervisor: data,
        manager: data,
      });
      contactArray.markAsDirty();
    }
  }

  private addNewContactRow() {
    const contactArray = this.form.get('MonLogContacts') as UntypedFormArray;

    const last = contactArray.controls[
      contactArray.length - 1
    ] as UntypedFormGroup;
    if (!last || !isNullOrUndefined(last.controls['primary'].value)) {
      contactArray.push(
        this._fb.group({
          primary: null,
          supervisor: { value: '', disabled: true },
          manager: { value: '', disabled: true },
        })
      );
    }
  }

  editEntry($event, isAction = false) {
    if (!this.canEdit) {
      this._logAndMessage.error(
        'Cannot edit monlog entry!',
        'Logged in user does not have appropriate claim.'
      );
      return;
    }

    this.addEntryComponent().setFormForEdit($event, isAction, this.monLog);
  }

  deleteEntry($event, isEntry = false) {
    if (!this.canEdit) {
      this._logAndMessage.error(
        'Cannot delete monlog entry!',
        'Logged in user does not have appropriate claim.'
      );
      return;
    }
    this._confirmationService.confirm({
      header: this.deleteEntryHeader,
      icon: 'fa fa-question-circle',
      acceptVisible: true,
      rejectVisible: true,
      acceptLabel: this.okBtnLabel,
      rejectLabel: this.cancelBtnLabel,
      message: this.deleteEntryMsg,
      accept: () => {
        this.loading = true;
        if (isEntry) {
          this._monLogApi.deleteMonLogEntry($event.id).subscribe(
            (value) => {
              this.refresh();
            },
            (error) => {
              this._logAndMessage.translateToErrorAlert({
                headerKey: 'MONLOG.MESSAGES.HEADERS.FAILED_DELETE_LOG_ENTRY',
                bodyKey: 'MONLOG.MESSAGES.ERROR.DELETE_MON_LOG_ENTRY',
              });
              console.error(error);
              this.loading = false;
            }
          );
        } else {
          this._monLogApi.deleteMonLogAlarm($event.id).subscribe(
            (value) => {
              this.refresh();
            },
            (error) => {
              this._logAndMessage.translateToErrorAlert({
                headerKey: 'MONLOG.MESSAGES.HEADERS.FAILED_DELETE_LOG_ENTRY',
                bodyKey: 'MONLOG.MESSAGES.ERROR.DELETE_MON_LOG_ENTRY',
              });
              console.error(error);
              this.loading = false;
            }
          );
        }
      },
      reject: () => {
        this.saving = false;
      },
    });
  }

  facilityTypeSelected($event: AutoCompleteSelectEvent) {}

  /**
   * Need to manually remove the ___-___-____ mask from the input mask so
   * the validation works.
   * @param field
   */
  fixMask(field) {
    const value: string = this.form.controls[field]
      ? this.form.controls[field].value
      : null;
    if (value && value.indexOf('_') > -1) {
      this.form.controls[field].setValue('');
      this.form.updateValueAndValidity();
    }
  }

  updateActionsList() {
    const selectAction$ = this._translateService.get(
      'MONLOG.LABEL.SELECT_ACTION'
    );
    const completeReview$ = this._translateService.get(
      'MONLOG.LABEL.COMPLETE_REVIEW'
    );
    const resolveLog$ = this._translateService.get('MONLOG.LABEL.RESOLVE_LOG');
    const deleteLog$ = this._translateService.get('MONLOG.LABEL.DELETE_LOG');
    const print$ = this._translateService.get('COMMON.LABEL.PRINT');
    const emailLog$ = this._translateService.get('MONLOG.LABEL.EMAIL');

    let select,
      complete,
      resolve,
      del,
      print = null;
    forkJoin([
      selectAction$,
      completeReview$,
      resolveLog$,
      deleteLog$,
      print$,
      emailLog$,
    ])
      .pipe(take(1))
      .subscribe((messages) => {
        select = messages[0];
        complete = messages[1];
        resolve = messages[2];
        del = messages[3];
        print = messages[4];
        this.emailDialogHeader = messages[5];

        this.actions = [
          { label: select, value: null },
          { label: print, value: PRINT },
        ];

        if (
          this.monLog &&
          this.monLog.logStatus === 'A' &&
          this._authApi.doesUserHaveAllClaimsFromList([CLAIMS.MONITORING_CENTER.MON_LOGS.COMPLETE_MON_LOG_REVIEWS])
        ) {
          if (!this.actions.some((a) => a.value === COMPLETE_REVIEW)) {
            this.actions.push({ label: complete, value: COMPLETE_REVIEW });
          }
        }

        // Log must be in review complete state to show the resolve log action
        // If logs didn't enter the review process, they would be resolved as soon
        // as a resolution entry is added
        if (this.monLog && this.monLog.logStatus === 'C' && this.canEdit) {
          if (!this.actions.some((a) => a.value === RESOLVE_LOG)) {
            this.actions.push({ label: resolve, value: RESOLVE_LOG });
          }
        }

        if (this.canDelete) {
          this.actions.push({ label: del, value: DELETE_LOG });
        }

        this.actions.push({ label: this.emailDialogHeader, value: EMAIL_LOG });

        // default actions here: Send to Day Crew, etc.
        this.actions = [...this.actions];
      });
  }

  actionEvent($event, object?: any) {
    if ($event.value) {
      this.selectedAction = null;
      if ($event.value) {
        if ($event.value === COMPLETE_REVIEW) {
          //#######################################################################
          this.form.patchValue({
            logStatus: 'C',
            reviewer: this._auth.getEmail(),
            reviewCompletedDt: new Date(),
          });
          this.form.controls['logStatus'].markAsDirty();
          this.form.controls['reviewer'].markAsDirty();
          this.form.controls['reviewCompletedDt'].markAsDirty();
          this.saveMonitoringLog();
          this.ngOnInit(); //reloading page
        } else if ($event.value === RESOLVE_LOG) {
          //#######################################################################
          if (!this.checkResolutionForGasRelease()) {
            return;
          }
          if (this.areThereOutstandingCallouts()) {
            this._logAndMessage.translateToErrorMessage({
              headerKey: 'MONLOG.MESSAGES.HEADERS.RESOLVE_LOG',
              bodyKey: 'MONLOG.MESSAGES.ERROR.OUTSTANDING_CALLOUT',
            });
            this.ngOnInit(); //reloading page
            return;
          }

          const hasRes = this.monLog.MonLogEntries.some(
            (a) => a.isResolution === true
          );
          if (hasRes === true) {
            this.form.patchValue({ logStatus: 'R' });
            this.form.controls['logStatus'].markAsDirty();
            this.saveMonitoringLog();
            this.ngOnInit(); //reloading page
          } else {
            this._logAndMessage.translateToErrorMessage({
              headerKey: 'MONLOG.MESSAGES.HEADERS.NO_RESOLUTION_FOUND',
              bodyKey: 'MONLOG.MESSAGES.ERROR.NO_RESOLUTION_FOUND_MSG',
            });
          }
          //#######################################################################
        } else if ($event.value === DELETE_LOG) {
          //#######################################################################
          this.deleteMonLog();
          this.ngOnInit(); //reloading page
          //#######################################################################
        } else if ($event.value === PRINT) {
          //#######################################################################
          const tab = TabService.getInstance().buildNewTab(
            MONLOG_PRINT_CONTAINER,
            true,
            null,
            this.monLog.id
          );
          if (this._deviceService.isMobile()) {
            TabService.getInstance().setMobileTab(tab);
          } else {
            TabService.getInstance().openTab(tab);
          }
        } else if ($event.value === EMAIL_LOG) {
          //#######################################################################
          this.displayEmailDialog = true;
          //#######################################################################
        }
        //#######################################################################
      }

      if (!isNullOrUndefined(object)) {
        object.clear(null);
      }
      if ($event.value === COMPLETE_REVIEW) {
        //#######################################################################
        this.form.patchValue({
          logStatus: 'C',
          reviewer: this._auth.getEmail(),
          reviewCompletedDt: new Date(),
        });
        this.form.controls['logStatus'].markAsDirty();
        this.form.controls['reviewer'].markAsDirty();
        this.form.controls['reviewCompletedDt'].markAsDirty();
        this.saveMonitoringLog();
        this.ngOnInit(); //reloading page
      } else if ($event.value === RESOLVE_LOG) {
        //#######################################################################
        if (!this.checkResolutionForGasRelease()) {
          return;
        }
        if (this.areThereOutstandingCallouts()) {
          this._logAndMessage.translateToErrorMessage({
            headerKey: 'MONLOG.MESSAGES.HEADERS.RESOLVE_LOG',
            bodyKey: 'MONLOG.MESSAGES.ERROR.OUTSTANDING_CALLOUT',
          });
          this.ngOnInit(); //reloading page
          this._cdRef.markForCheck();
          return;
        }

        const hasRes = this.monLog.MonLogEntries.some(
          (a) => a.isResolution === true
        );
        if (hasRes === true) {
          this.form.patchValue({ logStatus: 'R' });
          this.form.controls['logStatus'].markAsDirty();
          this.saveMonitoringLog();
          this.ngOnInit(); //reloading page
        } else {
          this._logAndMessage.translateToErrorMessage({
            headerKey: 'MONLOG.MESSAGES.HEADERS.NO_RESOLUTION_FOUND',
            bodyKey: 'MONLOG.MESSAGES.ERROR.NO_RESOLUTION_FOUND_MSG',
          });
        }
        //#######################################################################
      } else if ($event.value === DELETE_LOG) {
        //#######################################################################
        this.deleteMonLog();
        this.ngOnInit(); //reloading page
        //#######################################################################
      } else if ($event.value === EMAIL_LOG) {
        //#######################################################################
        this.displayEmailDialog = true;
        //#######################################################################
      }
      //#######################################################################
    }
    this._cdRef.markForCheck();
  }

  deleteMonLog(): void {
    if (!this.canDelete) {
      return;
    }

    this._confirmationService.confirm({
      header: this.deleteHeader,
      icon: 'fa fa-question-circle',
      acceptVisible: true,
      rejectVisible: true,
      acceptLabel: this.okBtnLabel,
      rejectLabel: this.cancelBtnLabel,
      message: this.deleteMsg,
      accept: () => {
        this.saving = true;
        this._cdRef.detectChanges();
        this._monLogApi
          .deleteMonitoringLog(this.monLog.id)
          .pipe(take(1))
          .subscribe(
            ({ data }) => {
              this.saving = false;
              this._logAndMessage.translateToSuccessMessage(
                {
                  bodyKey: 'COMMON.MESSAGES.SUCCESS.DELETE_SUCCESS_MSG',
                  headerKey: 'COMMON.MESSAGES.HEADERS.DELETE',
                },
                true
              );
              if (this._deviceService.isMobile()) {
                const tab = TabService.getInstance().buildNewTab(
                  ALL_MONLOG_SEARCH_CONTAINER,
                  true
                );
                TabService.getInstance().setMobileTab(tab);
              } else {
                const index = TabService.getInstance().getActiveIndex();
                TabService.getInstance().closeTab(index);
              }
            },
            (error) => {
              console.error(error);
              this.saving = false;
              this._logAndMessage.translateToErrorMessage({
                headerKey: 'COMMON.MESSAGES.HEADERS.DELETE_ERROR',
                bodyKey: 'COMMON.MESSAGES.ERROR.DELETE_ERROR_MSG',
              });
            }
          );
      },
      reject: () => {
        this.saving = false;
      },
    });
  }

  goToContact(id) {
    this._contactTable.setSelected(null);
    this._contactTable.setScreenEmbedded(false);
    const tab = TabService.getInstance().buildNewTab(
      CONTACT_DETAIL_CONTAINER,
      true,
      null,
      id
    );
    if (this._deviceService.isMobile()) {
      TabService.getInstance().setMobileTab(tab);
    } else {
      TabService.getInstance().openTab(tab);
    }
  }

  dispatchContact(id) {
    this.saving = true;
    this.monLog.MonLogCallouts.push({
      id: uuid(),
      ContactId: id,
      startDt: new Date(),
      dirtyStatus: DirtyStatus.NEW,
    });

    this._contactApi
      .getContactById(id)
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = Object.assign({}, data);
        const contact = this._contactBuilder.buildContact(clone.getContact);
        this._contactTable.setSelected(contact);
        this._translateService
          .get('MONLOG.LABEL.DISPATCH_NOTE', {
            firstName: contact.firstName,
            lastName: contact.lastName,
            jobRole: contact.jobRole,
            company: contact.companyAbbreviation,
            city: contact.city,
          })
          .pipe(take(1))
          .subscribe((message) => {
            this._monLogApi
              .createMonLogEntry(this.monLog.id, {
                isResolution: false,
                isAwaitingReview: false,
                note: message,
                time: new Date().toISOString(),
              })
              .subscribe({
                next: (logEntry) => {
                  this.monLog.MonLogEntries.push(logEntry);
                },
                error: (error) => {
                  console.error(error);
                },
              });
            this.saveMonitoringLog();
          });
      });
  }

  returnContact(id) {
    this.saving = true;
    const calloutArray = this.monLog.MonLogCallouts.filter(
      (co) => co.ContactId === id && co.endDt === null
    );
    if (calloutArray.length > 0) {
      const callout = calloutArray[0];
      callout.endDt = new Date();
      callout.dirtyStatus = DirtyStatus.UPDATED;
    }

    this._contactApi
      .getContactById(id)
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = Object.assign({}, data);
        const contact = this._contactBuilder.buildContact(clone.getContact);
        this._contactTable.setSelected(contact);
        this._translateService
          .get('MONLOG.LABEL.RETURN_NOTE', {
            firstName: contact.firstName,
            lastName: contact.lastName,
            jobRole: contact.jobRole,
            company: contact.companyAbbreviation,
            city: contact.city,
            businessMobile: contact.businessMobile,
          })
          .pipe(take(1))
          .subscribe((message) => {
            this._monLogApi
              .createMonLogEntry(this.monLog.id, {
                isResolution: false,
                isAwaitingReview: false,
                note: message,
                time: new Date().toISOString(),
              })
              .subscribe({
                next: (logEntry) => {
                  this.monLog.MonLogEntries.push(logEntry);
                },
                error: (error) => {
                  console.error(error);
                },
              });
            this.saveMonitoringLog();
          });
      });
  }

  deleteCallout(id) {
    const array = this.monLog.MonLogCallouts.filter((cl) => cl.id === id);
    if (array.length > 0) {
      const callout = array[0];
      callout.dirtyStatus = DirtyStatus.DELETED;

      this.saveMonitoringLog();
    }
  }

  addControllerEntry(index) {
    let controller = null;
    if (index === 0) {
      controller = this.monLog.internalOne;
    } else {
      controller = this.monLog.internalTwo;
    }

    if (controller) {
      this._translateService
        .get('MONLOG.LABEL.CONTROLLER_ENTRY', {
          primaryContact: controller.fullName,
          facilityName: this.monLog.Facility.facilityName,
          facilityUid: this.monLog.Facility.facilityUid,
        })
        .pipe(take(1))
        .subscribe((message) => {
          let newEntry = {
            id: uuid(),
            time: new Date(),
            user: this._auth.getUserName(),
            note: message,
            isResolution: false,
            dirtyStatus: DirtyStatus.NEW,
          };

          this.monLog.MonLogEntries.push(newEntry);
          this.addMonLogEntry(newEntry);
        });
    }
  }
  addMaintenanceEntry(isComplete = false) {
    let label = 'MONLOG.LABEL.MAINTENANCE_ENTRY';
    if (isComplete) {
      label = 'MONLOG.LABEL.MAINTENANCE_ENTRY_COMPLETE';
    }

    const firstContact = this.monLog.MonLogContacts[0];
    this._translateService
      .get(label, {
        primaryContact: firstContact.primaryFullName,
        facilityName: this.monLog.Facility.facilityName,
        facilityUid: this.monLog.Facility.facilityUid,
        workType: this.monLog.MonLogWorkTypes.map((i) => i.label).join(', '),
      })
      .pipe(take(1))
      .subscribe((message) => {
        if (this.areThereOutstandingCallouts() && isComplete) {
          this._logAndMessage.translateToErrorMessage({
            headerKey: 'MONLOG.MESSAGES.HEADERS.RESOLVE_LOG',
            bodyKey: 'MONLOG.MESSAGES.ERROR.OUTSTANDING_CALLOUT',
          });
          return;
        }
        let newEntry = {
          id: uuid(),
          time: new Date(),
          user: this._auth.getUserName(),
          note: message,
          isResolution: isComplete ? true : false,
          dirtyStatus: DirtyStatus.NEW,
        };

        this.monLog.MonLogEntries.push(newEntry);
        if (isComplete) {
          this.monLog.logStatus = 'R';
        }
        this.addMonLogEntry(newEntry);
      });
  }

  addMonLogEntry(raw: any) {
    this._monLogApi
      .createMonLogEntry(this.monLog.id, {
        isResolution: raw.isResolution ?? false,
        isAwaitingReview: raw.needReview ?? false,
        note: raw.note,
        time: raw.time ?? new Date().toISOString(),
      })
      .subscribe(
        (entry) => {
          this.refresh();
        },
        (error) => console.error(error)
      );
  }

  resetFacility() {
    this.form.patchValue({
      locationId: null,
      state: null,
      county: null,
      township: null,
    });
    this.form.controls['state'].markAsDirty();
    this.form.controls['county'].markAsDirty();
    this.form.controls['township'].markAsDirty();

    const group = this.form.get('Facility') as UntypedFormGroup;
    group.patchValue({
      facilityName: null,
      facilityType: null,
      aor: null,
      company: null,
      facilityId: null,
      facilityUid: null,
    });

    group.controls['facilityName'].markAsDirty();
    group.controls['facilityType'].markAsDirty();
    group.controls['aor'].markAsDirty();
    group.controls['company'].markAsDirty();
    group.controls['facilityId'].markAsDirty();
  }

  removeContactRow(index) {
    const contactArray = this.form.get('MonLogContacts') as UntypedFormArray;
    const control = contactArray.controls[index] as UntypedFormGroup;
    if (control && !isNullOrUndefined(control.controls['primary'].value)) {
      const id = control.controls['primary'].value.id;
      const array = this.monLog.MonLogContacts.filter((mc) => mc.id === id);
      if (array && array.length > 0) {
        const MonLogContact = array[0];
        MonLogContact.dirtyStatus = DirtyStatus.DELETED;
      }

      contactArray.removeAt(index);
    }
  }

  emailMonLog($event): void {
    this.saving = true;
    if (!isNullOrUndefined(this.monLog) && !isNullOrUndefined($event)) {
      const cEventData = $event;

      let sBCCList = cEventData.bccList;
      let sEmailNotes = cEventData.sEmailNotes;

      if (sBCCList === null || typeof sBCCList === 'undefined') {
        sBCCList = '';
      }

      if (sEmailNotes === null || typeof sEmailNotes === 'undefined') {
        sEmailNotes = '';
      }

      const req = this._adminBuilder.buildEmailRequest(
        this.monLog.id,
        cEventData.toList,
        sBCCList,
        sEmailNotes
      );

      this._monLogApi
        .emailMonLog(req)
        .pipe(take(1))
        .subscribe(
          (result) => {
            this.saveMonitoringLog();
            this.ngOnInit(); //reloading page
            this.displayEmailDialog = false;
          },
          (error) => {
            console.error(error);
            this.saving = false;
            this._logAndMessage.translateToErrorMessage({
              headerKey: 'MONLOG.MESSAGES.HEADERS.EMAIL_LOG',
              bodyKey: 'MONLOG.MESSAGES.ERROR.EMAIL_ERROR',
            });
          },
          () => {
            this.saving = false;
            this._logAndMessage.translateToSuccessMessage({
              headerKey: 'MONLOG.MESSAGES.HEADERS.EMAIL_LOG',
              bodyKey: 'MONLOG.MESSAGES.SUCCESS.EMAIL_SUCCESS',
            });
            this._cdRef.detectChanges();
          }
        );
    }
  }

  dialogHidden($event): void {
    this.displayEmailDialog = false;
  }

  areThereOutstandingCallouts(): boolean {
    if (
      !isNullOrEmptyArray(this.monLog.MonLogCallouts) &&
      !isNullOrEmptyArray(
        this.monLog.MonLogCallouts.filter((co) => isNullOrUndefined(co.endDt))
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  indexChanged($event): void {
    if (!isNullOrUndefined($event)) {
      this.tabIndex = $event.index;
    }
  }

  canUserAddAlarm(): boolean {
    return this._authApi.doesUserHaveAllClaimsFromList([CLAIMS.MONITORING_CENTER.MON_LOGS.ADD_MON_LOG_ALARMS]);
  }

  loadTypes(): void {
    this._monLogApi
      .loadAvailableLogTypes()
      .pipe(take(1))
      .subscribe((data) => {
        const clone = { ...data };
        const types = clone.data.getMonLogTypes.items;
        this.types = types
          .filter(
            (t) =>
              t.enabled === true ||
              (this.monLog.callType && this.monLog.callType.includes(t.name))
          )
          .map((t) => ({ label: t.name, value: t.id }))
          .sort(dynamicSort('label', 1));
      });
  }

  verifyCallTypeStillExist(changes): boolean {
    let stillExist = true;
    if (changes && changes.typeOfCalls) {
      changes.typeOfCalls.forEach((tc) => {
        const found = this.types.find((t) => t.value === tc);
        if (isNullOrUndefined(found)) {
          stillExist = false;
        }
        // allowSave = this.types.find((t) => t.value === tc) ? false : true;
      });
      return stillExist;
    } else {
      return stillExist;
    }
  }

  setupActiveTabChangedSubscription(): void {
    this.activeTabChangedSub =
      TabService.getInstance().activeTabChanged.subscribe((tab) => {
        if (
          !isNullOrUndefined(tab) &&
          ((tab.header.startsWith('*') &&
            tab.header.includes(this.screenName)) ||
            (!tab.header.startsWith('*') && tab.header === this.screenName))
        ) {
          this.loadTypes();
          const dv = getDirtyValues(this.form);
          const stillExist = this.verifyCallTypeStillExist(dv);
          if (!stillExist) {
            this.form.patchValue({
              typeOfCalls: [],
            });
            this._logAndMessage.translateToInfoMessage(
              {
                bodyKey: 'MONLOG.MESSAGES.INFO.CALL_TYPE_DELETED',
                headerKey: 'COMMON.MESSAGES.HEADERS.NOTIFICATION',
              },
              true
            );
          }
        }
      });
  }

  getIsPrimaryDispatched(contact: any): boolean {
    return contact['controls'].primary.value?.isPrimaryDispatched;
  }

  getCallouts(): any[] {
    //@ts-ignore
    return this.form.get('MonLogCallouts').controls;
  }

  getBaseFormChild(key: string): FormControl {
    return this.form.get(key) as FormControl;
  }

  crerEntryDeleted(entry: ControlRoomEventReviewEntry) {
    const entryIndex = this.monLog.ControlRoomEventReviewEntries?.findIndex(
      (crer) => crer.id === entry.id
    );
    this.monLog.ControlRoomEventReviewEntries?.splice(entryIndex, 1);
  }

  addEventReviewEntry() {
    this.eventReviewDialog().beginEditting(null);
  }

  editEventReviewEntry(entry: ControlRoomEventReviewEntry) {
    this.eventReviewDialog().beginEditting(entry);
  }

  doesAnyTypeRequireReview(): boolean {
    return this.monLog?.MonLogTypes?.some((mlt) => mlt.requiresCRER);
  }
}
